import React, { useEffect, useState } from "react";
import {
  approvedTransation,
  disapprovedTransation,
  getAllTransations,
} from "../../api/Api";
import { toast } from "react-toastify";
import Spinner from "../homeComp/commonComp/Spinner";
import DataTable from "react-data-table-component";
import Header from "../user/common/Header";

function AllTransations() {
  const [clientRows, setClientRows] = useState([]);
  const [filteredRows, setFilteredRows] = useState([]);
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState("");

  useEffect(() => {
    const fetchClients = async () => {
      setLoading(true);
      try {
        const res = await getAllTransations();
        if (res.status === "success") {
          setClientRows(
            Array.isArray(res.transactions) ? res.transactions : []
          );
          setFilteredRows(
            Array.isArray(res.transactions) ? res.transactions : []
          );
        } else {
          toast.error("Failed to fetch transactions.");
        }
      } catch (error) {
        toast.error("An error occurred while fetching transactions.");
      } finally {
        setLoading(false);
      }
    };

    fetchClients();
  }, []);

  useEffect(() => {
    if (Array.isArray(clientRows)) {
      const filteredData = clientRows.filter((item) => {
        const amountStr = item.amount ? item.amount.toString() : "";
        const transactionIdStr = item.transactionId
          ? item.transactionId.toLowerCase()
          : "";
        const userNameStr = item.userName
          ? item.userName.toString().toLowerCase()
          : "";
        const changesStr = item.changes ? item.changes.toString() : "";

        return (
          amountStr.includes(search.toLowerCase()) ||
          transactionIdStr.includes(search.toLowerCase()) ||
          userNameStr.includes(search.toLowerCase()) ||
          changesStr.includes(search.toLowerCase())
        );
      });
      setFilteredRows(filteredData);
    }
  }, [search, clientRows]);

  const getStatusText = (status) => {
    switch (status) {
      case -1:
        return "Rejected";
      case 0:
        return "Pending";
      case 1:
        return "Success";
      default:
        return "Unknown";
    }
  };

  const handleStatusApproved = async (id) => {
    setLoading(true);
    try {
      const result = await approvedTransation(id);
      if (result.status === "success") {
        toast.success(result.message || "Status updated successfully!");
        const res = await getAllTransations();
        if (res.status === "success") {
          setClientRows(
            Array.isArray(res.transactions) ? res.transactions : []
          );
        }
      } else {
        toast.error(result.message || "Failed to update status.");
      }
    } catch (error) {
      toast.error("An error occurred during status update. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const handleStatusReject = async (id) => {
    setLoading(true);
    try {
      const result = await disapprovedTransation(id);
      if (result.status === "success") {
        toast.error(result.message || "Status updated successfully!");
        const res = await getAllTransations();
        if (res.status === "success") {
          setClientRows(
            Array.isArray(res.transactions) ? res.transactions : []
          );
        }
      } else {
        toast.error(result.message || "Failed to update status.");
      }
    } catch (error) {
      toast.error("An error occurred during status update. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return <Spinner />;
  }

  const columns = [
    {
      name: "#",
      selector: (row, index) => index + 1,
      sortable: true,
      width: "60px",
    },
    {
      name: "User",
      selector: (row) => row.userName,
      sortable: true,
      width: "130px",
    },
    {
      name: "Amount",
      selector: (row) => row.amount,
      sortable: true,
      width: "130px",
    },
    {
      name: "Transaction ID",
      selector: (row) => row.transactionId,
      sortable: true,
      width: "170px",
    },
    {
      name: "Status",
      selector: (row) => getStatusText(row.status),
      sortable: true,
    },
    {
      name: "ScreenShot",
      selector: (row) => (
        <a rel="noreferrer" target="_blank" href={row.screenshot}>
          <img
            src={row.screenshot}
            alt="Screenshot"
            style={{ width: "100px", height: "100px", objectFit: "cover" }}
          />
        </a>
      ),
      sortable: true,
      width: "170px",
    },

    {
      name: "Created At",
      selector: (row) => new Date(row.createdAt).toLocaleDateString(),
      sortable: true,
    },
    {
      name: "Action",
      cell: (row) => (
        <div className="d-flex align-items-center gap-2">
          <button
            className="btn bg-success btn-sm text-white"
            onClick={() => handleStatusApproved(row._id)}
          >
            Approve
          </button>
          <button
            className="btn btn-sm bg-danger text-white"
            onClick={() => handleStatusReject(row._id)}
          >
            Reject
          </button>
        </div>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
      width: "200px",
    },
  ];

  return (
    <>
    <Header title="Transaction " />
      <div
        className="container"
        data-wow-duration="1s"
        data-wow-delay="0.1s"
        data-wow-offset={0}
        style={{
          visibility: "visible",
          animationDuration: "1s",
          animationDelay: "0.1s",
          animationName: "fadeInUp",
        }}
      >
      <div className="container">
        <div className="row gutters">
          <div className="col-md-12">
            <div className="card h-100">
              <div className="card-body">
                <div className="mb-3 w-100 d-flex justify-content-end">
                  <input
                    type="text"
                    className="form-control w-25"
                    placeholder="Search..."
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                  />
                </div>
                <DataTable
                  columns={columns}
                  data={filteredRows}
                  pagination
                  highlightOnHover
                  defaultSortField="createdAt"
                  striped
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      </div>
    </>
  );
}

export default AllTransations;
