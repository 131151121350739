import React, { useEffect, useRef } from 'react';

function CoinMarque() {
  const widgetRef = useRef(null);

  useEffect(() => {
    if (widgetRef.current) {
      try {
        // Clear previous widget (if any)
        widgetRef.current.innerHTML = '';

        // Create the script element
        const script = document.createElement('script');
        script.type = 'text/javascript';
        script.src = 'https://s3.tradingview.com/external-embedding/embed-widget-ticker-tape.js';
        script.async = true;

        // Widget configuration as a stringified object
        script.innerHTML = JSON.stringify({
          "symbols": [
            {
              "proName": "FOREXCOM:SPXUSD",
              "title": "S&P 500 Index"
            },
            {
              "proName": "FOREXCOM:NSXUSD",
              "title": "US 100 Cash CFD"
            },
            {
              "proName": "FX_IDC:EURUSD",
              "title": "EUR to USD"
            },
            {
              "proName": "BITSTAMP:BTCUSD",
              "title": "Bitcoin"
            },
            {
              "proName": "BITSTAMP:ETHUSD",
              "title": "Ethereum"
            },
            {
              "proName": "BITSTAMP:LTCUSD",
              "title": "Litecoin"
            },
            {
              "proName": "BINANCE:ADAUSD",
              "title": "Cardano"
            },
            {
              "proName": "BINANCE:DOGEUSD",
              "title": "Dogecoin"
            },
            {
              "proName": "BINANCE:SOLUSD",
              "title": "Solana"
            },
            {
              "description": "Apple",
              "proName": "NASDAQ:AAPL"
            },
            {
              "description": "Amazon",
              "proName": "NASDAQ:AMZN"
            }
          ],
          "showSymbolLogo": true,
          "isTransparent": false,
          "displayMode": "adaptive",
          "colorTheme": "light",
          "locale": "en"
        });

        // Append the script to the widgetRef container
        widgetRef.current.appendChild(script);
      } catch (error) {
        console.error("Error loading TradingView widget:", error);
      }
    }
  }, []);

  return (
    <div className="tradingview-widget-container" ref={widgetRef}>
      <div className="tradingview-widget-container__widget"></div>
      <div className="tradingview-widget-copyright">
        <a
          href="https://www.tradingview.com/"
          rel="noopener noreferrer"
          target="_blank"
        >
          <span className="blue-text">Track all markets on TradingView</span>
        </a>
      </div>
    </div>
  );
}

export default CoinMarque;
