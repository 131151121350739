import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { singleImageUpload, updateKyc } from "../../../api/Api";
import { toast } from "react-toastify";
import { setUser } from "../../../redux/actions/userProfile";

function UploadDoc() {
  const [identityFile, setIdentityFile] = useState(null);
  const [addressFile, setAddressFile] = useState(null);
  const [localBillFile, setLocalBillFile] = useState(null);
  const [loading, setLoading] = useState(false);

  const userToken = useSelector((state) => state.userData.token);
  const userData = useSelector((state) => state.userData.user);
  const dispatch = useDispatch();

  const handleFileChange = (event, setFileFunction) => {
    const file = event.target.files[0];
    if (file) {
      setFileFunction(file);
    }
  };

  const handleSubmitAll = async () => {
    if (!identityFile || !addressFile || !localBillFile) {
      toast.error("Please select files for all three documents.");
      return;
    }

    setLoading(true);
    try {
      const identityUrl = await uploadFile(identityFile);
      const addressUrl = await uploadFile(addressFile);
      const localBillUrl = await uploadFile(localBillFile);

      const result = await updateKyc(
        {
          identity: identityUrl,
          address: addressUrl,
          localBill: localBillUrl,
        },
        userToken
      );

      if (result.status === "success") {
        toast.info("Kyc has been submit for Verification.");
        const updatedUserData = {
          ...userData,
          kycDoc: {
            identity: identityUrl,
            address: addressUrl,
            localBill: localBillUrl,
            kycStatus: "0",
          },
        };
        dispatch(setUser(updatedUserData));

        resetForm();
      } else {
        toast.error(result.message || "Failed to upload documents.");
      }
    } catch (error) {
      toast.error("An error occurred while uploading documents.");
    } finally {
      setLoading(false);
    }
  };

  const uploadFile = async (file) => {
    const formdata = new FormData();
    formdata.append("image", file);
    const result = await singleImageUpload(formdata);
    if (result.status === "success") {
      return result.data;
    } else {
      throw new Error("Failed to upload image");
    }
  };

  const resetForm = () => {
    setIdentityFile(null);
    setAddressFile(null);
    setLocalBillFile(null);
    document.getElementById("identity").value = "";
    document.getElementById("address").value = "";
    document.getElementById("localBill").value = "";
  };

  return (
    <div>
      <div className="col-12 mt-3">
        <div className="form-group d-flex justify-content-start">
          <div>
            <label htmlFor="identity">
              <span className="fw-semibold">Identity</span>
            </label>
            <input
              type="file"
              className="form-control mt-2"
              id="identity"
              onChange={(e) => handleFileChange(e, setIdentityFile)}
              disabled={loading}
            />
          </div>
        </div>
      </div>

      <div className="col-12 mt-3">
        <div className="form-group d-flex justify-content-start">
          <div>
            <label htmlFor="address">
              <span className="fw-semibold">Address</span>
            </label>
            <input
              type="file"
              className="form-control mt-2"
              id="address"
              onChange={(e) => handleFileChange(e, setAddressFile)}
              disabled={loading}
            />
          </div>
        </div>
      </div>

      <div className="col-12 mt-3">
        <div className="form-group d-flex justify-content-start">
          <div>
            <label htmlFor="localBill">
              <span className="fw-semibold">Local Bill</span>
            </label>
            <input
              type="file"
              className="form-control mt-2"
              id="localBill"
              onChange={(e) => handleFileChange(e, setLocalBillFile)}
              disabled={loading}
            />
          </div>
        </div>
      </div>

      <div className="col-12 mt-4">
        <button
          className="btn btn-info text-white"
          onClick={handleSubmitAll}
          disabled={loading}
        >
          {loading ? (
            <>
              <span
                className="spinner-border spinner-border-sm me-2"
                role="status"
                aria-hidden="true"
              ></span>
              Uploading...
            </>
          ) : (
            "Submit"
          )}
        </button>
      </div>
    </div>
  );
}

export default UploadDoc;
