import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Spinner from "../components/homeComp/commonComp/Spinner";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";
import { loginWithEmail, getUserData } from "../api/Api";
import { useDispatch, useSelector } from "react-redux";
import { setToken, setUser } from "../redux/actions/userProfile";

function Login() {

  const navigate = useNavigate();
  const tokken = useSelector((state) => state.userData.token);
  useEffect(() => {
    if (tokken !== "") {
      navigate("/user/dashboard"); 
    }
  }, [tokken, navigate]);

  const dispatch = useDispatch();
  const [credentials, setCredentials] = useState({
    email: "",
    password: "",
  });
  const [loading, setLoading] = useState(false);

  const onChange = (e) => {
    setCredentials({ ...credentials, [e.target.name]: e.target.value });
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const result = await loginWithEmail(credentials);
      if (result && result.status === "success") {
        const userData = await getUserData(result.data);
        const token = result.data;
        dispatch(setToken(token));
        dispatch(setUser(userData.data));

        toast.success("Login successful!");
        navigate("/user/dashboard");
      } else {
        toast.error(result.message || "Failed to Login.");
      }
    } catch (error) {
      toast.error("An error occurred during login.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Spinner />
      <div className="container">
        <div className="row justify-content-center align-items-center vh-100">
          <div className="col-md-6 col-lg-6 col-sm-12 d-none d-md-block">
            <div className="text-center d-flex justify-content-center align-items-center">
              <img
                src="/assets/img/signinn.png"
                alt="icon"
                className="h-75 w-75"
              />
            </div>
          </div>
          <div className="col-md-12 col-lg-6 col-sm-12">
            <div className="d-flex justify-content-center align-items-center">
              <div className="card w-75 mt-4">
                <div className="card-body">
                  <div className="text-center">
                    <Link to="/">
                      <img
                        src="/assets/img/icon-1.png"
                        alt="icon"
                        className="mb-3"
                        height="59"
                      />
                    </Link>
                  </div>
                  <h1
                    className="card-subtitle text-center mb-4 display-4 fw-semibold"
                    style={{ color: "#16D5FF" }}
                  >
                    SignIn
                  </h1>
                  <form onSubmit={handleLogin}>
                    <div className="form-floating mb-3">
                      <input
                        type="email"
                        className="form-control"
                        id="email-reg"
                        placeholder="Email"
                        name="email"
                        value={credentials.email}
                        required
                        onChange={onChange}
                      />
                      <label htmlFor="email-reg">Email</label>
                    </div>
                    <div className="form-floating mb-3">
                      <input
                        type="password"
                        className="form-control"
                        id="password-reg"
                        placeholder="Password"
                        name="password"
                        value={credentials.password}
                        onChange={onChange}
                        required
                      />
                      <label htmlFor="password-reg">Password</label>
                    </div>
                    <div className="btn-regg">
                      <button
                        className="btn btn-block w-100 p-2"
                        type="submit"
                        style={{ backgroundColor: "#16D5FF" }}
                        disabled={loading}
                      >
                        {loading ? (
                          <>
                            <span
                              className="spinner-border spinner-border-sm me-2"
                              role="status"
                              aria-hidden="true"
                            ></span>
                          </>
                        ) : (
                          <span className="text-white fw-semibold">Sign In</span>
                        )}
                      </button>
                    </div>
                    <div className="mt-2">
                      <Link
                        className="btn-link text-info ms-auto fw-semibold"
                        to="/forgetpassword"
                        style={{ textDecoration: "none" }}
                      >
                        Forget Password?
                      </Link>
                    </div>
                    <div>
                      <p className="mt-4">
                        Don't have an Account?{" "}
                        <Link
                          className="btn-link text-info ms-auto fw-semibold"
                          to="/register"
                          style={{ textDecoration: "none" }}
                        >
                          Register
                        </Link>
                      </p>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Login;
