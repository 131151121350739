import React, { useEffect, useState } from "react";
import Header from "../common/Header";
import { faDollar } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { getAllSubscription } from "../../../api/Api";
import { toast } from "react-toastify";
import Spinner from "../../homeComp/commonComp/Spinner";

function Subplan() {
  const [loading, setLoading] = useState(false);
  const [clientRows, setClientRows] = useState([]);
  useEffect(() => {
    const fetchClients = async () => {
      setLoading(true);
      try {
        const res = await getAllSubscription();
        if (res.status === "success") {
          setClientRows(res.data);
        } else {
          toast.error("Failed to fetch clients.");
        }
      } catch (error) {
        toast.error("An error occurred while fetching clients.");
      } finally {
        setLoading(false);
      }
    };
    fetchClients();
  }, []);
  if (loading) {
    return <Spinner />;
  }

  return (
    <>
      <Header title="Subscription" />
      <section className="pricing-section pb-3">
        <div className="container">
          {/* Pricing Table starts */}
          <div className="row">
            {clientRows.map((item) => (
              <div className="col-md-4 mb-4">
                <div className="price-card rounded">
                  <h2>{item?.title}</h2>
                  <p>{item?.subTitle}</p>
                  <p className="price">
                    <span>
                      {item?.price}
                      <FontAwesomeIcon
                        className="fs-2 ms-1"
                        icon={faDollar}
                        style={{ color: "#16D5FF" }}
                      />
                    </span>
                    / Month
                  </p>
                  <ul className="pricing-offers">
                    <p>{item?.description}</p>
                  </ul>
                  <a
                    href="/"
                    className="btn btn-mid"
                    style={{ backgroundColor: "#16D5FF" }}
                  >
                    Buy Now
                  </a>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    </>
  );
}

export default Subplan;
