import React, { useEffect, useState } from "react";
import { getWithdrawHistory } from "../../../api/Api";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import DataTable from "react-data-table-component";
import Spinner from "../../homeComp/commonComp/Spinner";
import TransModal from "./TransModal";

function TransactionHistory() {
  const [transactions, setTransactions] = useState([]);
  const [filteredTransactions, setFilteredTransactions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedTransaction, setSelectedTransaction] = useState(null);
  const userToken = useSelector((state) => state.userData.token);

  useEffect(() => {
    const fetchTransactions = async () => {
      setLoading(true);
      try {
        const res = await getWithdrawHistory(userToken);
        if (res.status === "success") {
          const combinedTransactions = [
            ...(res.data.bankDetails || []),
            ...(res.data.cryptoDetails || []),
          ];
          setTransactions(combinedTransactions);
          setFilteredTransactions(combinedTransactions);
        } else {
          console.error("Failed to fetch transactions.");
        }
      } catch (error) {
        toast.error("An error occurred while fetching transactions.");
      } finally {
        setLoading(false);
      }
    };
    fetchTransactions();
  }, [userToken]);

  useEffect(() => {
    const filteredData = transactions.filter((transaction) =>
      transaction._id.toLowerCase().includes(searchTerm.toLowerCase()) ||
      new Date(transaction.createdAt).toLocaleDateString().toLowerCase().includes(searchTerm.toLowerCase()) ||
      getStatusText(transaction.status).toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredTransactions(filteredData);
  }, [searchTerm, transactions]);

  const getStatusText = (status) => {
    switch (status) {
      case -1:
        return "Rejected";
      case 0:
        return "Pending";
      case 1:
        return "Success";
      default:
        return "Unknown";
    }
  };

  const handlePreviewClick = (transaction) => {
    setSelectedTransaction(transaction);
  };

  const handleCloseModal = () => {
    setSelectedTransaction(null);
  };

  const columns = [
    {
      name: "#",
      selector: (row, index) => index + 1,
      width: "50px",
    },
    {
      name: "Transaction ID",
      selector: (row) => row._id,
      sortable: true,
    },
    {
      name: "Date Time",
      selector: (row) => new Date(row.createdAt).toLocaleDateString(),
      sortable: true,
    },
    {
      name: "Method",
      selector: (row) => (row.usdt ? "Crypto" : "Bank Transfer"),
      sortable: true,
    },
    {
      name: "Amount",
      selector: (row) => row.amount ,
      sortable: true,
    },
    {
      name: "Status",
      selector: (row) => getStatusText(row.status),
      sortable: true,
    },
    {
      name: "Action",
      cell: (row) => (
        <button
          className="btn btn-sm bg-success text-white"
          onClick={() => handlePreviewClick(row)}
        >
          Preview
        </button>
      ),
    },
  ];

  return (
    <>
      <div className="row mt-2">
        <div className="col-lg-12">
          <h3 className="">Transaction History</h3>
          <div className="card">
            <div className="card-body">
              <div className="table-responsive project-list">
                {loading ? (
                  <Spinner />
                ) : (
                  <>
                    <DataTable
                      columns={columns}
                      data={filteredTransactions}
                      pagination
                      highlightOnHover
                      striped
                      subHeader
                      subHeaderComponent={
                        <input
                          type="text"
                          placeholder="Search Transactions"
                          className="form-control w-25"
                          value={searchTerm}
                          onChange={(e) => setSearchTerm(e.target.value)}
                        />
                      }
                    />
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      {selectedTransaction && (
        <TransModal
          transaction={selectedTransaction}
          onClose={handleCloseModal}
        />
      )}
    </>
  );
}

export default TransactionHistory;
