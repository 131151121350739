import React, { useEffect, useState } from "react";
import { getAllSubscription, DeleteSubscriptionChart } from "../../api/Api";
import { toast } from "react-toastify";
import Spinner from "../homeComp/commonComp/Spinner";
import DataTable from "react-data-table-component";

function CreateSubTable() {
  const [clientRows, setClientRows] = useState([]);
  const [filteredRows, setFilteredRows] = useState([]);
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState("");

  useEffect(() => {
    const fetchClients = async () => {
      setLoading(true);
      try {
        const res = await getAllSubscription();
        if (res.status === "success") {
          setClientRows(res.data);
          setFilteredRows(res.data);
        } else {
          toast.error("Failed to fetch clients.");
        }
      } catch (error) {
        toast.error("An error occurred while fetching clients.");
      } finally {
        setLoading(false);
      }
    };

    fetchClients();
  }, []);

  useEffect(() => {
    const filtered = clientRows.filter((item) =>
      item.title.toLowerCase().includes(search.toLowerCase())
    );
    setFilteredRows(filtered);
  }, [search, clientRows]);

  const handleDelete = async (id) => {
    setLoading(true);
    try {
      const result = await DeleteSubscriptionChart(id);
      if (result.status === "success") {
        toast.success(result.message || "Deleted successfully!");
        const updatedRows = clientRows.filter((item) => item._id !== id);
        setClientRows(updatedRows);
        setFilteredRows(updatedRows);
      } else {
        toast.error(result.message || "Failed to delete.");
      }
    } catch (error) {
      toast.error("An error occurred during deletion. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return <Spinner />;
  }

  const columns = [
    {
      name: "#",
      selector: (item, index) => index + 1,
      sortable: true,
      width: "60px",
    },
    {
      name: "Title",
      selector: (item) => `${item.title}`,
      sortable: true,
    },
    {
      name: "Sub Title",
      selector: (item) => `${item.subTitle}`,
      sortable: true,
    },
    {
      name: "Price",
      selector: (item) => `${item.price}`,
      sortable: true,
      cell: (item) => (
        <span className="text-success text-uppercase">{item.price}</span>
      ),
    },
    {
      name: "Description",
      selector: (item) => `${item.description}`,
      sortable: true,
    },
    {
      name: "Action",
      cell: (item) => (
        <div className="d-flex gap-2">
          <button
            className="btn text-danger"
            onClick={() => handleDelete(item._id)}
          >
            <i className="bi bi-trash"></i>
          </button>
        </div>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
  ];

  return (
    <>
      <div className="container">
        <div className="row gutters">
          <div className="col-md-12">
            <div className="card h-100">
              <div className="card-body">
                <div className="mb-3 w-100 d-flex justify-content-end">
                  <input
                    type="text"
                    className="form-control w-25"
                    placeholder="Search..."
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                  />
                </div>
                <DataTable
                  columns={columns}
                  data={filteredRows}
                  pagination
                  highlightOnHover
                  defaultSortField="coinName"
                  striped
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default CreateSubTable;
