import React, {  useEffect, useState } from "react";
import OTPInput from "react-otp-input";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { setToken, setUser } from "../redux/actions/userProfile";
import { useDispatch, useSelector } from "react-redux";


function RegisterOtp() {
  const location = useLocation();
  const navigate = useNavigate();
  const tokken = useSelector((state) => state.userData.token);
  useEffect(() => {
    if (tokken !== "") {
      navigate("/user/dashboard"); 
    }
  }, [tokken, navigate]);
  const dispatch = useDispatch();
  const { state } = location;
  const [credentials, setCredentials] = useState({ otp: "" });


  const handleOtpChange = (enteredOtp) => {
    setCredentials({ ...credentials, otp: enteredOtp });
  };

  const handleSubmit = () => {
    if (state && state.otp === credentials.otp) {
      toast.success("OTP verified Successfully")
      dispatch(setToken(state.token));
      dispatch(setUser(state.userData));
      setTimeout(() => {
        navigate("/user/dashboard");
      }, 1000);
    } else {
      toast.error("The entered OTP is incorrect. Please try again.");
    }
  };

  return (
    <div className="container vh-100 d-flex justify-content-center align-items-center">
      <div className="col-lg-5 col-md-7">
        <div className="card shadow-md medd">
          <div className="card-body px-lg-5 py-lg-5 d-flex flex-column align-items-center ">
            <img
              src="/assets/img/icon-1.png"
              className="mb-3"
              alt="icon"
              height={80}
            />
            <h2 style={{ color: "#16D5FF" }}>Verify OTP</h2>
            <p className="mb-4" style={{ color: "#16D5FF" }}>
              Enter 6-digit code from your Email
            </p>
            <form
              onSubmit={(e) => {
                e.preventDefault();
                handleSubmit();
              }}
            >
              <div className="row blabla mb-4">
                <OTPInput
                requird
                  value={credentials.otp}
                  onChange={handleOtpChange}
                  numInputs={6}
                  renderSeparator={<span>-</span>}
                  renderInput={(props, index) => (
                    <input
                      {...props}
                      key={index}
                      style={{ width: "3rem", height: "3rem" }}
                      className="text-center"
                    />
                  )}
                />
              </div>
              <div className="text-center">
                <button
                  type="submit"
                  className="btn btn-lg my-4 text-white fw-semibold"
                  style={{ backgroundColor: "#16D5FF" }}
                >
                  Continue
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default RegisterOtp;
