import React, { useEffect, useState } from "react";
import Header from "../common/Header";
import { getAllUsersPriceChart } from "../../../api/Api";
import { toast } from "react-toastify";
import Spinner from "../../homeComp/commonComp/Spinner";
import DataTable from "react-data-table-component";

function Price() {
  const [clientRows, setClientRows] = useState([]);
  const [filteredRows, setFilteredRows] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchText, setSearchText] = useState("");

  useEffect(() => {
    const fetchClients = async () => {
      setLoading(true);
      try {
        const res = await getAllUsersPriceChart();

        if (res.status === "success") {
          setClientRows(res.data);
          setFilteredRows(res.data);
        } else {
          toast.error("Failed to fetch Data.");
        }
      } catch (error) {
        toast.error("An error occurred while fetching data.");
      } finally {
        setLoading(false);
      }
    };

    fetchClients();
  }, []);

  useEffect(() => {
    const filteredData = clientRows.filter(
      (client) =>
        client.coinName.toLowerCase().includes(searchText.toLowerCase()) ||
        client.amount.toString().includes(searchText) ||
        client.fiatValue.toString().includes(searchText) ||
        client.changes.toString().includes(searchText)
    );
    setFilteredRows(filteredData);
  }, [searchText, clientRows]);

  const columns = [
    { name: "#", selector: (row, index) => index + 1, sortable: true },
    { name: "Platform", selector: (row) => row.coinName, sortable: true },
    {
      name: "Amount",
      selector: (row) => `${row.amountSymbol}${row.amount}`,
      sortable: true,
    },
    { name: "", selector: () => "=", omit: true }, 
    {
      name: "Fiat Value",
      selector: (row) => `${row.amountSymbol}${row.fiatValue}`,
      sortable: true,
    },
    {
      name: "Change%",
      selector: (row) => `${row.changes}%`,
      sortable: true,
      cell: (row) => (
        <span
          className={`${
            parseFloat(row.changes) < 0 ? "text-danger" : "text-success"
          } text-uppercase`}
        >
          {row.changes}%
        </span>
      ),
    },
  ];

  if (loading) {
    return <Spinner />;
  }

  return (
    <>
      <Header title="Price Chart" />
      <div className="container">
        <div className="row gutters">
          <div className="col-md-12">
            <div className="card h-100">
              <div className="card-body">
                <div className="mb-3 w-100 d-flex justify-content-end">
                  <input
                    type="text"
                    placeholder="Search..."
                    className="form-control w-25"
                    value={searchText}
                    onChange={(e) => setSearchText(e.target.value)}
                  />
                </div>
                <div className="table-responsive">
                  <DataTable
                    columns={columns}
                    data={filteredRows}
                    pagination
                    highlightOnHover
                    striped
                    noHeader
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Price;
