import React, {  useState } from "react";
import Sidebar from "./common/Sidebar";
import Topnav from "./common/Topnav";
import Support from "../../components/admin/Support";


function ContactTable() {

  const [toogleNav, setToogleNav] = useState(false);
  return (
    <>
      <div className="main-container d-flex">
        <Sidebar
          toogleNav={toogleNav}
          setToogleNav={() => setToogleNav(false)}
        />
        <div className="content">
          <Topnav
            toogleNav={toogleNav}
            setToogleNav={() => setToogleNav(true)}
          />
          <div className="dashboard-content">
            <Support />
          </div>
        </div>
      </div>
    </>
  );
}

export default ContactTable;
