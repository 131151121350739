import React, { useEffect, useState } from "react";
import { getUserTransations } from "../../../api/Api";
import { toast } from "react-toastify";
import DataTable from "react-data-table-component";
import { useSelector } from "react-redux";
import ViewTransaction from "./ViewTransaction";

function ValueTable() {
  const [clientRows, setClientRows] = useState([]);
  const [filteredRows, setFilteredRows] = useState([]);
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState("");
  const [selectedTransaction, setSelectedTransaction] = useState(null);
  const userData = useSelector((state) => state.userData.user);

  useEffect(() => {
    const fetchClients = async () => {
      setLoading(true);
      try {
        const res = await getUserTransations(userData._id);
        if (res.status === "success") {
          setClientRows(
            Array.isArray(res.transactions) ? res.transactions : []
          );
          setFilteredRows(
            Array.isArray(res.transactions) ? res.transactions : []
          );
        } else {
          toast.error("Failed to fetch transactions.");
        }
      } catch (error) {
        toast.error("An error occurred while fetching transactions.");
      } finally {
        setLoading(false);
      }
    };

    fetchClients();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (Array.isArray(clientRows)) {
      const filteredData = clientRows.filter((item) => {
        const amountStr = item.amount ? item.amount.toString() : "";
        const transactionIdStr = item.transactionId
          ? item.transactionId.toLowerCase()
          : "";
        const userNameStr = item.userName
          ? item.userName.toString().toLowerCase()
          : "";
        const changesStr = item.changes ? item.changes.toString() : "";

        return (
          amountStr.includes(search.toLowerCase()) ||
          transactionIdStr.includes(search.toLowerCase()) ||
          userNameStr.includes(search.toLowerCase()) ||
          changesStr.includes(search.toLowerCase())
        );
      });
      setFilteredRows(filteredData);
    }
  }, [search, clientRows]);

  const getStatusText = (status) => {
    switch (status) {
      case -1:
        return "Rejected";
      case 0:
        return "Pending";
      case 1:
        return "Success";
      default:
        return "Unknown";
    }
  };

  if (loading) {
    return <div></div>;
  }

  const columns = [
    {
      name: "#",
      selector: (row, index) => index + 1,
      sortable: true,
    },
    {
      name: "Transaction ID",
      selector: (row) => row.transactionId,
      sortable: true,
    },
    {
      name: "Amount",
      selector: (row) => row.amount,
      sortable: true,
    },

    {
      name: "Status",
      selector: (row) => getStatusText(row.status),
      sortable: true,
    },

    {
      name: "Created At",
      selector: (row) => new Date(row.createdAt).toLocaleDateString(),
      sortable: true,
    },

    {
      name: "Action",
      cell: (row) => (
        <button
          data-bs-toggle="modal"
          data-bs-target="#viewtransaction"
          className="btn btn-sm bg-success text-white"
          onClick={() => setSelectedTransaction(row)}
        >
          Preview
        </button>
      ),
    },
  ];

  return (
    <>
      <div className="container pb-5">
        <div className="row gutters">
          <div className="col-md-12">
            <h3 className="">Value Unlock History</h3>
            <div className="card h-100">
              <div className="card-body">
                <div className="mb-3 w-100 d-flex justify-content-end">
                  <input
                    type="text"
                    className="form-control w-25"
                    placeholder="Search..."
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                  />
                </div>
                <DataTable
                  columns={columns}
                  data={filteredRows}
                  pagination
                  highlightOnHover
                  defaultSortField="createdAt"
                  striped
                />
              </div>
            </div>
          </div>
        </div>
        <ViewTransaction transaction={selectedTransaction} />
      </div>
    </>
  );
}

export default ValueTable;
