import React, { useEffect, useState } from "react";
import Header from "../user/common/Header";
import { toast } from "react-toastify";
import {
  ApprovedStatusBank,
  DisapprovedStatusBank,
  getAllBankDetails,
} from "../../api/Api";
import Spinner from "../homeComp/commonComp/Spinner";
import DataTable from "react-data-table-component";

function Withdraw() {
  const [clientRows, setClientRows] = useState([]);
  const [filteredRows, setFilteredRows] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchText, setSearchText] = useState("");

  useEffect(() => {
    const fetchClients = async () => {
      setLoading(true);
      try {
        const res = await getAllBankDetails();

        if (res.status === "success") {
          setClientRows(res.data);
          setFilteredRows(res.data); // Initialize filteredRows with full data
        } else {
          toast.error("Failed to fetch clients.");
        }
      } catch (error) {
        toast.error("An error occurred while fetching clients.");
      } finally {
        setLoading(false);
      }
    };

    fetchClients();
  }, []);
  useEffect(() => {
    const filteredData = clientRows.filter((client) => {
      return (
        (client.fullName &&
          client.fullName.toLowerCase().includes(searchText.toLowerCase())) ||
        (client.bankName &&
          client.bankName.toLowerCase().includes(searchText.toLowerCase())) ||
        (client.bankAddress &&
          client.bankAddress
            .toLowerCase()
            .includes(searchText.toLowerCase())) ||
        (client.swiftCode &&
          client.swiftCode.toLowerCase().includes(searchText.toLowerCase())) ||
        (client.accountNumber &&
          client.accountNumber
            .toLowerCase()
            .includes(searchText.toLowerCase())) ||
        (client.ifscCode &&
          client.ifscCode.toLowerCase().includes(searchText.toLowerCase())) ||
        (client.routingNumber &&
          client.routingNumber
            .toLowerCase()
            .includes(searchText.toLowerCase())) ||
        (client.amount && client.amount.toString().includes(searchText))
      );
    });
    setFilteredRows(filteredData);
  }, [searchText, clientRows]);

  const getStatusText = (status) => {
    switch (status) {
      case -1:
        return "Rejected";
      case 0:
        return "Pending";
      case 1:
        return "Success";
      default:
        return "Unknown";
    }
  };

  const handleStatusApproved = async (id) => {
    setLoading(true);
    try {
      const result = await ApprovedStatusBank(id);
      if (result.status === "success") {
        toast.success(result.message || "Status updated successfully!");
        const res = await getAllBankDetails();
        if (res.status === "success") {
          setClientRows(res.data);
        }
      } else {
        toast.error(result.message || "Failed to update status.");
      }
    } catch (error) {
      toast.error("An error occurred during status update. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const handleStatusReject = async (id) => {
    setLoading(true);
    try {
      const result = await DisapprovedStatusBank(id);
      if (result.status === "success") {
        toast.error(result.message || "Status updated successfully!");
        const res = await getAllBankDetails();
        if (res.status === "success") {
          setClientRows(res.data);
        }
      } else {
        toast.error(result.message || "Failed to update status.");
      }
    } catch (error) {
      toast.error("An error occurred during status update. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const columns = [
    {
      name: "#",
      selector: (row, index) => index + 1,
      sortable: true,
      width: "50px",
    },
    { name: "Name", selector: (row) => row.fullName, sortable: true },
    { name: "Bank Name", selector: (row) => row.bankName, sortable: true },
    { name: "Bank Address", selector: (row) => row.bankAddress },
    { name: "Swift Code", selector: (row) => row.swiftCode },
    { name: "Account Number", selector: (row) => row.accountNumber },
    { name: "Ifsc Code", selector: (row) => row.ifscCode },
    { name: "Routing Number", selector: (row) => row.routingNumber },
    { name: "Amount", selector: (row) => row.amount, sortable: true },
    {
      name: "Created At",
      selector: (row) => new Date(row.createdAt).toLocaleDateString(),
      sortable: true,
    },
    {
      name: "Status",
      selector: (row) => getStatusText(row.status),
      sortable: true,
    },
    {
      name: "Action",
      cell: (row) => (
        <div className="d-flex align-items-center gap-2">
          <button
            className="btn bg-success btn-sm text-white"
            onClick={() => handleStatusApproved(row._id)}
          >
            Approve
          </button>
          <button
            className="btn btn-sm bg-danger text-white"
            onClick={() => handleStatusReject(row._id)}
          >
            Reject
          </button>
        </div>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
      width: "200px",
    },
  ];

  if (loading) {
    return <Spinner />;
  }

  return (
    <>
      <Header title="Bank Withdraw" />
      <div className="container">
        <div className="row gutters">
          <div className="col-12">
            <div className="card mt-4">
              <div className="card-body">
                <div className="mb-3 w-100 d-flex justify-content-end">
                  <input
                    type="text "
                    placeholder="Search..."
                    className="form-control w-25"
                    value={searchText}
                    onChange={(e) => setSearchText(e.target.value)}
                  />
                </div>
                <div className="table-responsive table-scroll">
                  <DataTable
                    columns={columns}
                    data={filteredRows}
                    pagination
                    highlightOnHover
                    striped
                    noHeader
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Withdraw;
