import React from "react";
import Header from "../common/Header";
import TransationHistory from "./TransationHistory";
import ValueTable from "./ValueTable";

function Dash() {
  return (
    <>
      <Header title="Dashboard" />
      <div
        className="container"
        data-wow-duration="1s"
        data-wow-delay="0.1s"
        data-wow-offset={0}
        style={{
          visibility: "visible",
          animationDuration: "1s",
          animationDelay: "0.1s",
          animationName: "fadeInUp",
        }}
      >
        <div className="row row-cols-1 row-cols-md-2 row-cols-xl-4">
          <div className="col">
            <div className="card radius-10 border-start border-0 border-3 border-info">
              <div className="card-body p-4">
                <div className="d-flex align-items-center">
                  <div>
                    <p className="mb-0 text-secondary">Available</p>
                    <h4 className="my-1 text-info">4805</h4>
                    <p className="mb-0 font-13">+2.5% from last week</p>
                  </div>
                  <div className="widgets-icons-2 rounded-circle bg-gradient-scooter text-white ms-auto">
                    <i className="fa fa-shopping-cart" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col">
            <div className="card radius-10 border-start border-0 border-3 border-danger">
              <div className="card-body p-4">
                <div className="d-flex align-items-center">
                  <div>
                    <p className="mb-0 text-secondary">In Process</p>
                    <h4 className="my-1 text-danger">$84,245</h4>
                    <p className="mb-0 font-13">+5.4% from last week</p>
                  </div>
                  <div className="widgets-icons-2 rounded-circle bg-gradient-bloody text-white ms-auto">
                    <i className="fa fa-dollar" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col">
            <div className="card radius-10 border-start border-0 border-3 border-success">
              <div className="card-body p-4">
                <div className="d-flex align-items-center">
                  <div>
                    <p className="mb-0 text-secondary">Withdraw</p>
                    <h4 className="my-1 text-success">34.6%</h4>
                    <p className="mb-0 font-13">-4.5% from last week</p>
                  </div>
                  <div className="widgets-icons-2 rounded-circle bg-gradient-ohhappiness text-white ms-auto">
                    <i className="fa fa-bar-chart" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col">
            <div className="card radius-10 border-start border-0 border-3 border-warning">
              <div className="card-body p-4">
                <div className="d-flex align-items-center">
                  <div>
                    <p className="mb-0 text-secondary">Transactions</p>
                    <h4 className="my-1 text-warning">8.4K</h4>
                    <p className="mb-0 font-13">+8.4% from last week</p>
                  </div>
                  <div className="widgets-icons-2 rounded-circle bg-gradient-blooker text-white ms-auto">
                    <i className="fa fa-users" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="container mb-5 pb-3"
        data-wow-duration="1s"
        data-wow-delay="0.1s"
        data-wow-offset={0}
        style={{
          visibility: "visible",
          animationDuration: "1s",
          animationDelay: "0.1s",
          animationName: "fadeInUp",
        }}
      >
        <TransationHistory/>
        <ValueTable/>
      </div>
    </>
  );
}

export default Dash;
