import React, { useEffect, useState } from "react";
import Sidebar from "../../components/user/common/Sidebar";
import Topnav from "../../components/user/common/Topnav";
import C2 from "../../components/user/dashboard/C2";
import { useDispatch, useSelector } from "react-redux";
import { getUserData } from "../../api/Api";
import { setUser } from "../../redux/actions/userProfile";
import { toast } from "react-toastify";

function Dashboard() {
  const [toogleNav, setToogleNav] = useState(false);
  const userToken = useSelector((state) => state.userData.token);
  const dispatch = useDispatch();

  const handleUserData = async (e) => {
    try {
      const userData = await getUserData(userToken);
      dispatch(setUser(userData.data));
    } catch (error) {
      toast.error("An error occurred during login.");
    } finally {
    }
  };
  useEffect(() => {
    handleUserData();
    // eslint-disable-next-line
    }, []);
  return (
    <>
      <div className="main-container d-flex">
        <Sidebar
          toogleNav={toogleNav}
          setToogleNav={() => setToogleNav(false)}
        />
        <div className="content">
          <Topnav
            toogleNav={toogleNav}
            setToogleNav={() => setToogleNav(true)}
          />
          <div className="dashboard-content">
            <C2 />
          </div>
        </div>
      </div>
    </>
  );
}

export default Dashboard;
