import React, { useEffect, useState } from "react";
import Header from "../user/common/Header";
import { getAllUsers, userStatus } from "../../api/Api";
import { toast } from "react-toastify";
import Spinner from "../homeComp/commonComp/Spinner";
import DataTable from "react-data-table-component";
import { Link } from "react-router-dom";

function GetAllUsers() {
  const [clientRows, setClientRows] = useState([]);
  const [filteredRows, setFilteredRows] = useState([]);
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState("");

  useEffect(() => {
    const fetchClients = async () => {
      setLoading(true);
      try {
        const res = await getAllUsers();
        if (res.status === "success") {
          setClientRows(res.data);
          setFilteredRows(res.data);
        } else {
          toast.error("Failed to fetch clients.");
        }
      } catch (error) {
        toast.error("An error occurred while fetching clients.");
      } finally {
        setLoading(false);
      }
    };
    fetchClients();
  }, []);

  useEffect(() => {
    const filteredData = clientRows.filter(
      (item) =>
        (item.fullName &&
          item.fullName.toLowerCase().includes(search.toLowerCase())) ||
        (item.email &&
          item.email.toLowerCase().includes(search.toLowerCase())) ||
        (item.phone &&
          item.phone.toLowerCase().includes(search.toLowerCase())) ||
        (item.country &&
          item.country.toLowerCase().includes(search.toLowerCase()))
    );

    setFilteredRows(filteredData);
  }, [search, clientRows]);

  const handleStatus = async (id) => {
    setLoading(true);
    try {
      const result = await userStatus({ id });
      if (result.status === "success") {
        toast.success(result.message || "Status updated successfully!");
        setClientRows((prevRows) =>
          prevRows.map((row) =>
            row._id === id ? { ...row, isActive: !row.isActive } : row
          )
        );
      } else {
        toast.error(result.message || "Failed to update status.");
      }
    } catch (error) {
      toast.error("An error occurred during status update. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return <Spinner />;
  }
  const columns = [
    {
      name: "#",
      selector: (row, index) => index + 1,
      sortable: true,
      width: "50px",
    },
    {
      name: "Name",
      selector: (row) => row.fullName,
      sortable: true,
    },
    {
      name: "Email",
      selector: (row) => row.email,
      sortable: true,
    },
    {
      name: "Phone",
      selector: (row) => row.phone,
      sortable: true,
    },
    {
      name: "Address",
      selector: (row) => row.address,
      sortable: true,
    },
    {
      name: "Country",
      selector: (row) => row.country,
      sortable: true,
    },
    {
      name: "KYC Status",
      selector: (row) => {
        const kycStatus = row.kycDoc.kycStatus;
        if (kycStatus === "-1") {
          return <span className="badge bg-danger">Rejected</span>;
        } else if (kycStatus === "1") {
          return <span className="badge bg-success">Approved</span>;
        } else if (kycStatus === "0") {
          return <span className="badge bg-warning">Pending</span>;
        }
        return <span className="badge bg-secondary">Not Submitted</span>;
      },
      sortable: true,
    },
    {
      name: "Action",
      cell: (row) => (
        <div className="d-flex align-items-center gap-2">
          <Link to={`/admin/user/${row._id}`}>
            <button className="btn btn-sm btn-secondary text-nowrap">
              View
            </button>
          </Link>
          <button
            onClick={() => handleStatus(row._id)}
            className={`btn btn-sm text-nowrap ${
              row.isActive ? "bg-danger" : "bg-success"
            } text-white`}
          >
            {row.isActive ? "Inactive" : "Active"}
          </button>
        </div>
      ),
      sortable: false,
    },
  ];
  return (
    <>
      <Header title="Users" />
      <div className="container pb-4">
        <div className="row gutters">
          <div className="col-lg-12">
            <div className="card">
              <div className="card-body">
                <div className="mb-3 w-100 d-flex justify-content-end">
                  <input
                    type="text"
                    className="form-control w-25"
                    placeholder="Search users..."
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                  />
                </div>
                <DataTable
                  columns={columns}
                  data={filteredRows}
                  pagination
                  paginationPerPage={10}
                  paginationRowsPerPageOptions={[10, 20, 30, 50]}
                  sortable
                  striped
                  highlightOnHover
                  defaultSortFieldId={1}
                  noHeader
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default GetAllUsers;
