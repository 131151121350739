import React, { useState } from "react";
import Sidebar from "../../components/user/common/Sidebar";
import Topnav from "../../components/user/common/Topnav";
import Value from "../../components/user/dashboard/Value";

function ValueUnlock() {
  const [toogleNav, setToogleNav] = useState(false);

  return (
    <>
      <div className="main-container d-flex">
        <Sidebar
          toogleNav={toogleNav}
          setToogleNav={() => setToogleNav(false)}
        />
        <div className="content">
          <Topnav
            toogleNav={toogleNav}
            setToogleNav={() => setToogleNav(true)}
          />
          <div className="dashboard-content">
            <Value />
          </div>
        </div>
      </div>
    </>
  );
}

export default ValueUnlock;
