import React from "react";
function Header(props) {
  return (
    <div className="container">
      <header className="d-flex flex-wrap justify-content-center py-1 mb-4 border-bottom">
        <a
          href="/"
          className="d-flex align-items-center mb-3 mb-md-0 me-md-auto link-body-emphasis text-decoration-none"
        >
          <span className="fs-3 fw-bold ms-2">{props.title}</span>
        </a>
      </header>
    </div>
  );
}

export default Header;
