import React from 'react'

function About() {
  return (
   <div className="container-xxl py-5" id='about'>
  <div className="container">
    <div className="row g-5 align-items-center">
      <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.1s">
        <img className="img-fluid" src="/assets/img/about.png" alt='icon' />
      </div>
      <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.5s">
        <div className="h-100">
          <h1 className="display-6">About Us</h1>
          <p className="fs-5 mb-4" style={{color:'#16D5FF'}}>The Most Trusted Cryptocurrency Platform</p>
          <p>Tempor erat elitr rebum at clita. Diam dolor diam ipsum sit. Aliqu diam amet diam et eos.
            Clita erat ipsum et lorem et sit, sed stet lorem sit clita duo justo magna dolore erat amet
          </p>
          <p className="mb-4">Tempor erat elitr rebum at clita. Diam dolor diam ipsum sit. Aliqu diam amet
            diam et eos. Clita erat ipsum et lorem et sit.</p>
          <div className="d-flex align-items-center mb-2">
            <i className="fa fa-check bg-light btn-sm-square rounded-circle me-3 fw-bold" style={{color:'#16D5FF'}}/>
            <span>Tempor erat elitr rebum at clita</span>
          </div>
          <div className="d-flex align-items-center mb-2">
            <i className="fa fa-check bg-light btn-sm-square rounded-circle me-3 fw-bold" style={{color:'#16D5FF'}}/>
            <span>Tempor erat elitr rebum at clita</span>
          </div>
          <div className="d-flex align-items-center mb-4">
            <i className="fa fa-check bg-light btn-sm-square rounded-circle me-3 fw-bold" style={{color:'#16D5FF'}}/>
            <span>Tempor erat elitr rebum at clita</span>
          </div>
          <a className="btn btn-block py-3 px-4 fw-semibold text-white" href='/' style={{backgroundColor:'#16D5FF'}}>Read More</a>
        </div>
      </div>
    </div>
  </div>
</div>

  )
}

export default About
