import React, { useState } from "react";
import Header from "../user/common/Header";
import { singleImageUpload, createValueUnlock } from "../../api/Api";
import { toast } from "react-toastify";
import ValueUnlockTable from "./ValueUnlockTable";

function UnlockValue() {
  const [file, setFile] = useState(false);
  const [value, setValue] = useState({
    name: "",
    icon: "",
    network: "",
    address: "",
    minAmount: "",
    maxAmount: "",
    currencyType: "",
    url: "",
    email: "",
  });
  const [error, setError] = useState(""); 
  const [loading, setLoading] = useState(false);

  const onChange = (e) => {
    setValue({ ...value, [e.target.name]: e.target.value });

    if (e.target.name === "minAmount" || e.target.name === "maxAmount") {
      validateAmount(e.target.name, e.target.value);
    }
  };

  const validateAmount = (name, amount) => {
    const minAmount = name === "minAmount" ? parseFloat(amount) : parseFloat(value.minAmount);
    const maxAmount = name === "maxAmount" ? parseFloat(amount) : parseFloat(value.maxAmount);

    if (isNaN(minAmount) || isNaN(maxAmount)) {
      setError("Please enter valid numbers for amounts.");
    } else if (minAmount > maxAmount) {
      setError("Min Amount cannot be greater than Max Amount.");
    } else {
      setError(""); // Clear error when valid
    }
  };

  const handleIconChange = async (e) => {
    const file = e.target.files[0];
    if (file) {
      try {
        const uploadedIcon = await uploadFile(file);
        setValue((prevState) => ({
          ...prevState,
          icon: uploadedIcon,
        }));
        toast.success("Icon uploaded successfully!");
      } catch (error) {
        toast.error("Failed to upload icon");
      }
    }
  };

  const uploadFile = async (file) => {
    setLoading(true);
    const formdata = new FormData();
    formdata.append("image", file);
    const result = await singleImageUpload(formdata);
    if (result.status === "success") {
      setFile(result.data);
      setLoading(false);
      return result.data;
    } else {
      setLoading(false);
      throw new Error("Failed to upload image");
    }
  };

  const onSubmit = async (e) => {
    e.preventDefault();

    if (!file) {
      toast.error("Please upload an icon before submitting.");
      return;
    }

    if (error) {
      toast.error("Please correct the validation errors before submitting.");
      return;
    }

    setLoading(true);

    try {
      const result = await createValueUnlock(value);
      if (result && result.status === "success") {
        toast.success(result.message || "Successfully Created");
        setValue({
          name: "",
          icon: "",
          network: "",
          address: "",
          minAmount: "",
          maxAmount: "",
          currencyType: "",
          url: "",
          email: "",
        });
        window.location.reload();
      } else {
        toast.error(result.message || "Failed to send request.");
      }
    } catch (error) {
      toast.error("An error occurred while creating the entry.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Header title="Value Unlock" />
      <div
        className="container"
        data-wow-duration="1s"
        data-wow-delay="0.1s"
        data-wow-offset={0}
        style={{
          visibility: "visible",
          animationDuration: "1s",
          animationDelay: "0.1s",
          animationName: "fadeInUp",
        }}
      >
        <div className="container pb-4">
          <div className="row gutters">
            <div className="col-12 mt-2">
              <div className="card h-100">
                <div className="card-body">
                  <form onSubmit={onSubmit}>
                    <div className="row gutters">
                      <div className="col-md-6 col-12 mb-2">
                        <div className="form-group">
                          <label htmlFor="name">
                            <span className="fw-semibold">Name</span>
                          </label>
                          <input
                            type="text"
                            className="form-control mt-1"
                            name="name"
                            required
                            onChange={onChange}
                            value={value.name}
                          />
                        </div>
                      </div>
                      <div className="col-md-6 col-12 mb-2">
                        <div className="form-group">
                          <label htmlFor="icon">
                            <span className="fw-semibold">Icon</span>
                          </label>
                          <input
                            type="file"
                            className="form-control mt-1"
                            name="icon"
                            required
                            onChange={handleIconChange}
                          />
                        </div>
                      </div>
                      <div className="col-md-6 col-12 mb-2">
                        <div className="form-group">
                          <label htmlFor="currencyType">
                            <span className="fw-semibold">Type</span>
                          </label>
                          <select
                            className="form-control mt-1"
                            name="currencyType"
                            onChange={onChange}
                            value={value.currencyType}
                          >
                            <option selected disabled value="">
                              Select Type
                            </option>
                            <option value="Fiat">Fiat</option>
                            <option value="Crypto">Crypto</option>
                          </select>
                        </div>
                      </div>
                      <div className="col-md-6 col-12 mb-2">
                        <div className="form-group">
                          <label htmlFor="url">
                            <span className="fw-semibold">Network</span>
                          </label>
                          <input
                            type="text"
                            className="form-control mt-1"
                            name="network"
                            required
                            onChange={onChange}
                            value={value.network}
                          />
                        </div>
                      </div>
                      <div className="col-md-6 col-12 mb-2">
                        <div className="form-group">
                          <label htmlFor="url">
                            <span className="fw-semibold">URL</span>
                          </label>
                          <input
                            type="text"
                            className="form-control mt-1"
                            name="url"
                            required
                            onChange={onChange}
                            value={value.url}
                          />
                        </div>
                      </div>
                      <div className="col-md-6 col-12 mb-2">
                        <div className="form-group">
                          <label htmlFor="email">
                            <span className="fw-semibold">Email</span>
                          </label>
                          <input
                            type="email"
                            className="form-control mt-1"
                            name="email"
                            onChange={onChange}
                            value={value.email}
                          />
                        </div>
                      </div>
                      <div className="col-md-6 col-12 mb-2">
                        <div className="form-group">
                          <label htmlFor="minAmount">
                            <span className="fw-semibold">Min Amount</span>
                          </label>
                          <input
                            type="number"
                            className="form-control mt-1"
                            name="minAmount"
                            required
                            onChange={onChange}
                            value={value.minAmount}
                          />
                        </div>
                      </div>
                      <div className="col-md-6 col-12 mb-2">
                        <div className="form-group">
                          <label htmlFor="maxAmount">
                            <span className="fw-semibold">Max Amount</span>
                          </label>
                          <input
                            type="number"
                            className="form-control mt-1"
                            name="maxAmount"
                            required
                            onChange={onChange}
                            value={value.maxAmount}
                          />
                        </div>
                      </div>
                      <div className="col-md-6 col-12 mb-2">
                        <div className="form-group">
                          <label htmlFor="address">
                            <span className="fw-semibold">Address</span>
                          </label>
                          <input
                            type="text"
                            className="form-control mt-1"
                            name="address"
                            onChange={onChange}
                            value={value.address}
                          />
                        </div>
                      </div>
                    </div>
                    {error && <small className="text-danger">{error}</small>}
                    <div className="row gutters">
                      <div className="col-12">
                        <div className="text-right mt-3 d-flex justify-content-end">
                          <button
                            type="submit"
                            className="btn btn-info text-white ms-2"
                            disabled={loading || error !== ""}
                          >
                            {loading ? "Adding..." : "Add"}
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ValueUnlockTable />
      </div>
    </>
  );
}

export default UnlockValue;
