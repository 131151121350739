import React from "react";
import Navbar from "../components/homeComp/commonComp/Navbar";
import Spinner from "../components/homeComp/commonComp/Spinner";
import Footer from "../components/homeComp/commonComp/Footer";
import Header from "../components/homeComp/Header";
import About from "../components/homeComp/About";
import Features from "../components/homeComp/Features";
import Facts from "../components/homeComp/Facts";
import Services from "../components/homeComp/Services";
import Faqs from "../components/homeComp/Faqs";
import Contact from "../components/homeComp/Contact";

function Home() {
  return (
    <div>
      <Spinner />
      <Navbar />
      <Header />
      <About />
      <Facts />
      <Features />
      <Services />
      <Faqs />
      <Contact />
      <Footer />
    </div>
  );
}

export default Home;
