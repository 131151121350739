import React, { useState } from "react";
import Header from "../user/common/Header";
import CreateSubTable from "./CreateSubTable";
import { toast } from "react-toastify";
import { CreateSubscription } from "../../api/Api";

function CreateSubs() {
  const [item, setItem] = useState({
    title: "",
    subTitle: "",
    price: "",
    description: "",
  });

  const [loading, setLoading] = useState(false);

  const onChange = (e) => {
    setItem({ ...item, [e.target.name]: e.target.value });
  };

  const handleSubscription = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const result = await CreateSubscription(item);
      if (result && result.status === "success") {
        toast.success(result.message || "Successfully Created");
        setItem({
          title: "",
          subTitle: "",
          price: "",
          description: "",
        });
        window.location.reload();
      } else {
        toast.error(result.message || "Failed to Send Request.");
      }
    } catch (error) {
      toast.error("An error occurred while creating the subscription.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Header title="Create Subscription" />
      <div
        className="container"
        data-wow-duration="1s"
        data-wow-delay="0.1s"
        data-wow-offset={0}
        style={{
          visibility: "visible",
          animationDuration: "1s",
          animationDelay: "0.1s",
          animationName: "fadeInUp",
        }}
      >
        <div className="container pb-4">
          <div className="row gutters">
            <div className="col-12 mt-2">
              <div className="card h-100">
                <div className="card-body">
                  <form onSubmit={handleSubscription}>
                    <div className="row gutters">
                      <div className=" col-md-4 col-12 mb-2">
                        <div className="form-group">
                          <label htmlFor="phone">
                            <span className="fw-semibold">Title</span>
                          </label>
                          <input
                            type="text"
                            className="form-control mt-1"
                            name="title"
                            value={item.title}
                            required
                            onChange={onChange}
                          />
                        </div>
                      </div>
                      <div className=" col-md-4 col-12 mb-2">
                        <div className="form-group">
                          <label htmlFor="phone">
                            <span className="fw-semibold">Sub Title</span>
                          </label>
                          <input
                            type="text"
                            className="form-control mt-1"
                            name="subTitle"
                            value={item.subTitle}
                            required
                            onChange={onChange}
                          />
                        </div>
                      </div>
                      <div className=" col-md-4 col-12 mb-2">
                        <div className="form-group">
                          <label htmlFor="phone">
                            <span className="fw-semibold">Price</span>
                          </label>
                          <input
                            type="number"
                            className="form-control mt-1"
                            name="price"
                            value={item.price}
                            required
                            onChange={onChange}
                          />
                        </div>
                      </div>
                      <div className=" col-md-12 col-12 mb-2">
                        <div className="form-group">
                          <label
                            htmlFor="exampleFormControlTextarea1"
                            className="form-label fw-semibold"
                          >
                            Description
                          </label>
                          <textarea
                            className="form-control"
                            id="exampleFormControlTextarea1"
                            rows="4"
                            name="description"
                            value={item.description}
                            required
                            onChange={onChange}
                          ></textarea>
                        </div>
                      </div>
                    </div>
                    <div className="row gutters">
                      <div className="col-12">
                        <div className="text-right mt-3 d-flex justify-content-end">
                          <button
                            type="submit"
                            className="btn btn-info text-white ms-2"
                            disabled={loading}
                          >
                            {loading ? "Adding..." : "Add"}
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
        <CreateSubTable />
      </div>
    </>
  );
}

export default CreateSubs;
