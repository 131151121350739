import React from "react";
import Header from "../common/Header";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCircleCheck,
  faCircleXmark,
  faHourglassHalf,
} from "@fortawesome/free-regular-svg-icons";
import UploadDoc from "./UploadDoc";
import { useSelector } from "react-redux";

function Verify() {
  const userData = useSelector((state) => state.userData.user);

  const getVerificationIcon = (status) => {
    switch (status) {
      case "1":
        return (
          <FontAwesomeIcon className="text-success" icon={faCircleCheck} />
        );
      case "0":
        return (
          <FontAwesomeIcon className="text-warning" icon={faHourglassHalf} />
        );
      case "-1":
        return <FontAwesomeIcon className="text-danger" icon={faCircleXmark} />;
      default:
        return null;
    }
  };

  const getVerificationText = (status) => {
    switch (status) {
      case "1":
        return "Verified";
      case "0":
        return "Pending";
      case "-1":
        return "Rejected Please Submit Again";
      default:
        return "Document Not Submitted Yet";
    }
  };

  return (
    <>
      <Header title="Verification" />
      <div className="container">
        <div className="row gutters">
          <div className="col-lg-12 col-md-12 col-sm-12">
            <div className="card veri">
              <div className="card-body">
                <div className="row gutters">
                  <div className="col-md-12">
                    <h5>
                      Email Verified
                      <span className="badge bg-success ms-1">
                        <i
                          className="bi bi-check-circle
                           fs-5 fw-bold"
                        ></i>
                      </span>
                    </h5>
                  </div>

                  {/* Conditionally render the UploadDoc component */}
                  {userData?.kycDoc?.kycStatus !== "1" && <UploadDoc />}
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-4 col-md-4 col-12 p-3">
              <div className="card border-info p-3 d-flex justify-content-center align-self-center">
                <div className="card-body p-3">
                  <div className="d-flex justify-content-center align-content-center align-self-center">
                    <span className="display-4">
                      {getVerificationIcon(userData?.kycDoc?.kycStatus)}
                    </span>
                  </div>
                  <div className="d-flex justify-content-center align-content-center">
                    <h4 className="mt-2">
                      Identity{" "}
                      {getVerificationText(userData?.kycDoc?.kycStatus)}
                    </h4>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-12 p-3">
              <div className="card w-100 border-info p-3 d-flex justify-content-center align-self-center">
                <div className="card-body p-3">
                  <div className="d-flex justify-content-center align-content-center align-self-center">
                    <span className="display-4">
                      {getVerificationIcon(userData?.kycDoc?.kycStatus)}
                    </span>
                  </div>
                  <div className="d-flex justify-content-center align-content-center">
                    <h4 className="mt-2">
                      Address {getVerificationText(userData?.kycDoc?.kycStatus)}
                    </h4>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-12 p-3">
              <div className="card w-100 border-info p-3 d-flex justify-content-center align-self-center">
                <div className="card-body p-3">
                  <div className="d-flex justify-content-center align-content-center align-self-center">
                    <span className="display-4">
                      {getVerificationIcon(userData?.kycDoc?.kycStatus)}
                    </span>
                  </div>
                  <div className="d-flex justify-content-center align-content-center">
                    <h4 className="mt-2">
                      Local Bill{" "}
                      {getVerificationText(userData?.kycDoc?.kycStatus)}
                    </h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Verify;
