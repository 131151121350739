import React, { useEffect, useState } from "react";
import { singleImageUpload, valueUnlockTransation } from "../../../api/Api";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";

function TransationModel({ selectedData }) {
  const [amount, setAmount] = useState("");
  const [error, setError] = useState("");
  const [copySuccess, setCopySuccess] = useState("");
  const [file, setFile] = useState(false);
  const [loading, setLoading] = useState(false);
  const userData = useSelector((state) => state.userData.user);

  const [value, setValue] = useState({
    userId: userData._id,
    valueUnlockId: selectedData?._id || "",
    transactionId: "",
    amount: "",
    screenshot: "",
  });

  useEffect(() => {
    if (selectedData) {
      setValue((prevState) => ({
        ...prevState,
        valueUnlockId: selectedData._id,
      }));
    }
  }, [selectedData]);

  const handleAmountChange = (e) => {
    const value = parseFloat(e.target.value);
    if (isNaN(value)) {
      setError("Please enter a valid number");
    } else if (
      value < selectedData.minAmount ||
      value > selectedData.maxAmount
    ) {
      setError(
        `Please enter an amount between ${selectedData.minAmount} and ${selectedData.maxAmount}`
      );
    } else {
      setError("");
    }
    setAmount(e.target.value);
    setValue((prevState) => ({
      ...prevState,
      amount: e.target.value,
    }));
  };

  const handleCopy = (textToCopy) => {
    navigator.clipboard
      .writeText(textToCopy)
      .then(() => {
        setCopySuccess("Address Copied!");
        setTimeout(() => setCopySuccess(""), 3000);
      })
      .catch(() => {
        setCopySuccess("Failed to copy");
      });
  };

  const handleScreenshot = async (e) => {
    const file = e.target.files[0];
    if (file) {
      try {
        const uploadedscreenshot = await uploadFile(file);
        setValue((prevState) => ({
          ...prevState,
          screenshot: uploadedscreenshot,
        }));
        toast.success("Screenshot uploaded successfully!");
      } catch (error) {
        toast.error("Failed to upload Screenshot");
      }
    }
  };

  const uploadFile = async (file) => {
    setLoading(true);
    const formdata = new FormData();
    formdata.append("image", file);
    const result = await singleImageUpload(formdata);
    if (result.status === "success") {
      setFile(result.data);
      setLoading(false);
      return result.data;
    } else {
      setLoading(false);
      throw new Error("Failed to upload image");
    }
  };
  const onChange = (e) => {
    setValue({ ...value, [e.target.name]: e.target.value });
  };
  const handleTransation = async (e) => {
    e.preventDefault();

    if (!file) {
      toast.error("Please upload Screenshot before submitting.");
      return;
    }
    setLoading(true);
    try {
      const result = await valueUnlockTransation(value);
      if (result && result.status === "success") {
        toast.success(result.message || "Successfully Submitted");
        setValue({
          transactionId: "",
          amount: "",
          screenshot: "",
        });
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      } else {
        toast.error(result.message || "Failed to send request.");
      }
    } catch (error) {
      toast.error("An error occurred while creating the entry.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div
      className="modal fade"
      id="transationModel"
      tabIndex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog">
        <form onSubmit={handleTransation}>
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="exampleModalLabel">
                {selectedData?.name}
              </h1>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>

            <div className="modal-body">
              <div className="text-center">
                <div className="mb-3">{selectedData?.network}</div>
                <div>
                  <a
                    target="_blank"
                    without
                    rel="noreferrer noopener"
                    href={selectedData?.url}
                    className="mb-3"
                  >
                    {selectedData?.url}
                  </a>
                </div>
                <img
                  className="w-50"
                  src={selectedData?.icon}
                  alt={selectedData?.name}
                />
              </div>
              <div className="container">
                {selectedData?.currencyType === "Crypto" ? (
                  <div className="input-group input-group-sm mt-3">
                    <button
                      className="btn btn-outline-info"
                      type="button"
                      onClick={() => handleCopy(selectedData?.url)}
                    >
                      Copy Address
                    </button>
                    <input
                      type="text"
                      className="form-control"
                      placeholder=""
                      aria-label="Example text with button addon"
                      aria-describedby="button-addon1"
                      value={selectedData?.address}
                      readOnly
                    />
                  </div>
                ) : (
                  <div className="input-group input-group-sm mt-3">
                    <button
                      className="btn btn-outline-info"
                      type="button"
                      onClick={() => handleCopy(selectedData?.email)}
                    >
                      Copy Email
                    </button>
                    <input
                      type="text"
                      className="form-control"
                      placeholder=""
                      aria-label="Example text with button addon"
                      aria-describedby="button-addon1"
                      value={selectedData?.email}
                      readOnly
                    />
                  </div>
                )}

                {copySuccess && (
                  <small className="text-success">{copySuccess}</small>
                )}

                <div className="input-group input-group-sm mt-3">
                  <button className="btn btn-outline-info" disabled>
                    Enter Amount
                  </button>
                  <input
                    type="number"
                    className="form-control"
                    placeholder={`Enter amount between ${selectedData?.minAmount} and ${selectedData?.maxAmount}`}
                    aria-label="Example text with button addon"
                    aria-describedby="button-addon1"
                    value={amount}
                    onChange={handleAmountChange}
                  />
                </div>
                {error && <small className="text-danger">{error}</small>}

                <div className="input-group input-group-sm mt-3">
                  <button className="btn btn-outline-info" disabled>
                    Paste ID
                  </button>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Transaction ID or Hash ID"
                    aria-label="Example text with button addon"
                    aria-describedby="button-addon1"
                    value={value.transactionId}
                    name="transactionId"
                    onChange={onChange}
                  />
                </div>

                <div className="input-group input-group-sm mt-3">
                  <button className="btn btn-outline-info" disabled>
                    Upload Screenshot
                  </button>
                  <input
                    type="file"
                    className="form-control"
                    aria-label="Example text with button addon"
                    aria-describedby="button-addon1"
                    onChange={handleScreenshot}
                  />
                </div>
              </div>
            </div>

            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                Close
              </button>
              <button
                type="submit"
                className="btn btn-info text-light"
                disabled={error !== "" || loading}
              >
                {loading ? (
                  <>
                    <span
                      className="spinner-border spinner-border-sm me-2"
                      role="status"
                      aria-hidden="true"
                    ></span>
                    Loading...
                  </>
                ) : (
                  "Submit"
                )}
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}

export default TransationModel;
