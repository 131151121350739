import React, { useState, useEffect } from 'react';

function Spinner() {
  const [showSpinner, setShowSpinner] = useState(true);

  useEffect(() => {
    // This function will hide the spinner after 1 second
    const hideSpinner = () => {
      setShowSpinner(false);
    };

    const spinnerTimeout = setTimeout(hideSpinner, 800); // 1000ms = 1 second

    return () => {
      clearTimeout(spinnerTimeout); // Clear the timeout if the component unmounts
    };
  }, []);

  return (
    <div>
      {showSpinner && (
        <div
          id="spinner"
          className="show bg-white position-fixed translate-middle w-100 vh-100 top-50 start-50 d-flex align-items-center justify-content-center"
        >
          <div className="spinner-grow text-info" role="status"></div>
        </div>
      )}
    </div>
  );
}

export default Spinner;
