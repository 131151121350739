import React from "react";

const getStatusBadge = (status) => {
  switch (status) {
    case -1:
      return { text: "Rejected", badgeClass: "badge bg-danger" };
    case 0:
      return { text: "Pending", badgeClass: "badge bg-warning" };
    case 1:
      return { text: "Success", badgeClass: "badge bg-success" };
    default:
      return { text: "Unknown", badgeClass: "badge bg-secondary" };
  }
};

function ViewTransaction({ transaction }) {
  if (!transaction) return null;

  const { text: statusText, badgeClass } = getStatusBadge(transaction.status);

  return (
    <div
      className="modal fade"
      id="viewtransaction"
      tabIndex="-1"
      aria-labelledby="viewTransactionLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-lg">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="viewTransactionLabel">
              Transaction Details
            </h5>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>

          <div className="modal-body">
            <div className="row mb-3">
              <div className="col-md-6">
                <h6>Transaction ID:</h6>
                <p>{transaction.transactionId}</p>
              </div>
              <div className="col-md-6">
                <h6>Status:</h6>
                <span className={badgeClass}>{statusText}</span>
              </div>
            </div>

            <div className="row mb-3">
              <div className="col-md-6">
                <h6>Amount:</h6>
                <p>${transaction.amount}</p>
              </div>
              <div className="col-md-6">
                <h6>Date:</h6>
                <p>{new Date(transaction.createdAt).toLocaleDateString()}</p>
              </div>
            </div>

            {transaction.screenshot && (
              <div className="row">
                <div className="col-12">
                  <h6>Screenshot:</h6>
                  <img
                    src={transaction.screenshot}
                    alt="Transaction Screenshot"
                    className="img-fluid rounded"
                    style={{ maxHeight: "300px", objectFit: "cover" }}
                  />
                </div>
              </div>
            )}
          </div>

          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-secondary"
              data-bs-dismiss="modal"
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ViewTransaction;
