import React, { useState } from "react";
import Header from "../user/common/Header";
import { useSelector } from "react-redux";
import { changeAdminPassword } from "../../api/Api";
import { toast } from "react-toastify";

function Password() {
  const userToken = useSelector((state) => state.userData.token);
  const [loading, setLoading] = useState(false);
  const [credentials, setCredentials] = useState({
    oldPassword: "",
    newPassword: "",
    confirmPassword: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setCredentials((prevCredentials) => ({
      ...prevCredentials,
      [name]: value,
    }));
  };

  const handlePasswordSubmit = async (e) => {
    e.preventDefault();
    if (credentials.newPassword !== credentials.confirmPassword) {
      toast.error("New password and confirmation do not match.");
      return;
    }

    setLoading(true);

    try {
      const result = await changeAdminPassword(
        credentials.oldPassword,
        credentials.newPassword,
        userToken
      );
      if (result && result.status === "success") {
        toast.success(result.message || "Password updated successfully.");
        setCredentials({
          oldPassword: "",
          newPassword: "",
          confirmPassword: "",
        });
      } else {
        toast.error(result.message || "Failed to update password.");
      }
    } catch (error) {
      toast.error("An error occurred while updating the password.");
    }

    setLoading(false);
  };

  return (
    <>
      <Header title="Change Password" />
      <div
        className="container"
        data-wow-duration="1s"
        data-wow-delay="0.1s"
        data-wow-offset={0}
        style={{
          visibility: "visible",
          animationDuration: "1s",
          animationDelay: "0.1s",
          animationName: "fadeInUp",
        }}
      >
        <div className="container pb-4">
          <div className="row gutters">
            <div className="col-12 mt-2">
              <div className="card h-100">
                <div className="card-body">
                  <form onSubmit={handlePasswordSubmit}>
                    <div className="row gutters">
                      <div className="col-md-4 col-12 mb-2">
                        <div className="form-group">
                          <label htmlFor="oldPassword">
                            <span className="fw-semibold">
                              Current Password
                            </span>
                          </label>
                          <input
                            type="password"
                            className="form-control mt-1"
                            name="oldPassword"
                            value={credentials.oldPassword}
                            onChange={handleInputChange}
                            required
                          />
                        </div>
                      </div>
                      <div className="col-md-4 col-12 mb-2">
                        <div className="form-group">
                          <label htmlFor="newPassword">
                            <span className="fw-semibold">New Password</span>
                          </label>
                          <input
                            type="password"
                            className="form-control mt-1"
                            name="newPassword"
                            value={credentials.newPassword}
                            onChange={handleInputChange}
                            required
                          />
                        </div>
                      </div>
                      <div className="col-md-4 col-12 mb-2">
                        <div className="form-group">
                          <label htmlFor="confirmPassword">
                            <span className="fw-semibold">
                              Confirm Password
                            </span>
                          </label>
                          <input
                            type="password"
                            className="form-control mt-1"
                            name="confirmPassword"
                            value={credentials.confirmPassword}
                            onChange={handleInputChange}
                            required
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row gutters">
                      <div className="col-12">
                        <div className="text-right mt-3 d-flex justify-content-end">
                          <button
                            type="button"
                            className="btn btn-secondary"
                            onClick={() =>
                              setCredentials({
                                currentPassword: "",
                                newPassword: "",
                                confirmPassword: "",
                              })
                            }
                          >
                            Cancel
                          </button>
                          <button
                            type="submit"
                            className="btn btn-info text-white ms-2"
                            disabled={loading}
                          >
                            {loading ? "Updating..." : "Update"}
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Password;
