import React, { useState } from "react";
import Header from "../common/Header";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { setUser } from "../../../redux/actions/userProfile";
import { updateUserData } from "../../../api/Api";
import Profile from "./Profile";
import Password from "./Password";

function Set() {
  const userData = useSelector((state) => state.userData.user);
  const userToken = useSelector((state) => state.userData.token);
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const [credentials, setCredentials] = useState({
    streetAddress: userData.streetAddress,
    phone: userData.phone,
    fullName: userData.fullName,
    email: userData.email,
    city: userData.city,
    state: userData.state,
    country: userData.country,
    zipCode: userData.zipCode,
    profilePicture: userData.profilePicture,
  });

  const onChange = (e) => {
    setCredentials({ ...credentials, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true); // Start loading
    const result = await updateUserData(credentials, userToken);
    if (result && result.status === "success") {
      toast.success("Profile Updated!");
      dispatch(setUser(credentials));
    } else {
      toast.error(result.message || "Failed to Update Profile.");
    }
    setLoading(false);
  };

  return (
    <>
      <Header title="Setting" />
      <div className="container pb-4">
        <div className="row gutters">
          <div className="col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12">
            <div className="card h-100 klk">
              <div className="card-body">
                <div className="account-settings">
                  <div className="user-profile">
                    <Profile userData={userData} userToken={userToken} />
                    <h5 className="user-name">{userData.fullName}</h5>
                    <h6 className="user-email">{userData.email}</h6>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-9 col-lg-9 col-md-12 col-sm-12 col-12 mt-2">
            <div className="card h-100">
              <div className="card-body">
                <form onSubmit={handleSubmit}>
                  <div className="row gutters">
                    <div className="col-xl-12 d-flex justify-content-between">
                      <h4 className="mb-3 text-info">Personal Details</h4>
                      <button type="button" className="btn btn-info text-white" data-bs-toggle="modal" data-bs-target="#changeModal">Change Password</button>
                    </div>
                    <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12 mb-2">
                      <div className="form-group">
                        <label htmlFor="fullName">
                          <span className="fw-semibold">Full Name</span>
                        </label>
                        <input
                          type="text"
                          className="form-control mt-1"
                          name="fullName"
                          placeholder="Enter Full Name"
                          disabled
                          value={userData.fullName}
                        />
                      </div>
                    </div>
                    <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12 mb-2">
                      <div className="form-group">
                        <label htmlFor="eMail">
                          <span className="fw-semibold">Email</span>
                        </label>
                        <input
                          type="email"
                          className="form-control mt-1"
                          name="email"
                          disabled
                          value={userData.email}
                          placeholder="Enter Your Email Address"
                        />
                      </div>
                    </div>
                    <div className="col-xl-6 col-lg-12 col-md-12 col-sm-6 col-12 mb-2">
                      <div className="form-group">
                        <label htmlFor="phone">
                          <span className="fw-semibold">Phone</span>
                        </label>
                        <input
                          type="text"
                          className="form-control mt-1"
                          id="phone"
                          name="phone"
                          value={credentials.phone}
                          onChange={onChange}
                          placeholder="Enter Your Phone#"
                          required
                        />
                      </div>
                    </div>
                    <div className="col-xl-6 col-lg-12 col-md-12 col-sm-6 col-12 mb-2">
                      <div className="form-group">
                        <label htmlFor="address">
                          <span className="fw-semibold">Address</span>
                        </label>
                        <input
                          type="text"
                          className="form-control mt-1"
                          id="address"
                          value={credentials.streetAddress}
                          onChange={onChange}
                          name="streetAddress"
                          placeholder="Enter Your Address"
                          required
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row gutters">
                    <div className="col-12">
                      <h4 className="mt-3 mb-2 text-info">Address</h4>
                    </div>
                    <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12 mb-2">
                      <div className="form-group">
                        <label htmlFor="country">
                          <span className="fw-semibold">Country</span>
                        </label>
                        <input
                          type="text"
                          className="form-control mt-1"
                          id="country"
                          value={credentials.country}
                          onChange={onChange}
                          name="country"
                          placeholder="Enter Country"
                          required
                        />
                      </div>
                    </div>
                    <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12 mb-2">
                      <div className="form-group">
                        <label htmlFor="ciTy">
                          <span className="fw-semibold">City</span>
                        </label>
                        <input
                          type="text"
                          className="form-control mt-1"
                          id="ciTy"
                          value={credentials.city}
                          onChange={onChange}
                          name="city"
                          placeholder="Enter City"
                          required
                        />
                      </div>
                    </div>
                    <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12 mb-2">
                      <div className="form-group">
                        <label htmlFor="sTate">
                          <span className="fw-semibold">State</span>
                        </label>
                        <input
                          type="text"
                          className="form-control mt-1"
                          id="sTate"
                          value={credentials.state}
                          onChange={onChange}
                          name="state"
                          placeholder="Enter State"
                          required
                        />
                      </div>
                    </div>
                    <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12 mb-2">
                      <div className="form-group">
                        <label htmlFor="zIp">
                          <span className="fw-semibold">Zip Code</span>
                        </label>
                        <input
                          type="text"
                          className="form-control mt-1"
                          id="zIp"
                          value={credentials.zipCode}
                          onChange={onChange}
                          name="zipCode"
                          placeholder="Zip Code"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row gutters">
                    <div className="col-12">
                      <div className="text-right mt-3 d-flex justify-content-end">
                        <button
                          type="submit"
                          className="btn btn-info text-white ms-2"
                          disabled={loading}
                        >
                          {loading ? "Updating..." : "Update"}
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <Password/>
      </div>
    </>
  );
}

export default Set;
