import React, { useState } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { newslatter } from "../../../api/Api";

function Footer() {
  const [credentials, setCredentials] = useState({
    email: "",
  });
  const [loading, setLoading] = useState(false); // Loading state

  const onChange = (e) => {
    setCredentials({ ...credentials, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true); // Start loading
    try {
      const result = await newslatter(credentials.email);
      if (result && result.status === "success") {
        toast.success(result.message || "Newsletter Subscribe Successfully");
        setCredentials({ email: "" });
      } else {
        toast.error(result.message || "Failed to Sign Up.");
      }
    } catch (error) {
      toast.error("An error occurred. Please try again.");
    } finally {
      setLoading(false); // Stop loading
    }
  };

  return (
    <div
      className="container-fluid bg-light footer mt-5 pt-5 wow fadeIn"
      data-wow-delay="0.1s"
    >
      <div className="container py-5">
        <div className="row g-5">
          <div className="col-md-6">
            <a
              href="index.html"
              className="navbar-brand mb-4 d-flex align-items-center"
            >
              <h2 className="m-0 fw-bold">
                <img
                  className="img-fluid me-2"
                  src="/assets/img/icon-1.png"
                  alt="icon"
                  style={{ width: 45 }}
                />
                <span style={{ color: "rgb(22, 213, 255)" }}>
                  {" "}
                  We Monitize4u
                </span>
              </h2>
            </a>
            <span>
              Diam dolor diam ipsum sit. Aliqu diam amet diam et eos. Clita erat
              ipsum et lorem et sit, sed stet lorem sit clita. Diam dolor diam
              ipsum sit. Aliqu diam amet diam et eos. Clita erat ipsum et lorem
              et sit.
            </span>
          </div>
          <div className="col-md-6">
            <h5 className="mb-4">Newsletter</h5>
            <p>Clita erat ipsum et lorem et sit, sed stet lorem sit clita.</p>
            <div className="position-relative">
              <input
                className="form-control bg-transparent w-100 py-3 ps-4 pe-5"
                type="email"
                name="email"
                onChange={onChange}
                value={credentials.email}
                placeholder="Your email"
              />
              <button
                type="button"
                className="btn py-2 px-3 position-absolute top-0 end-0 mt-2 me-2 text-white"
                onClick={handleSubmit}
                style={{ backgroundColor: "#16D5FF" }}
                disabled={loading} // Disable button when loading
              >
                {loading ? (
                  <span
                    className="spinner-border spinner-border-sm"
                    role="status"
                    aria-hidden="true"
                  ></span>
                ) : (
                  "Send Mail"
                )}
              </button>
            </div>
          </div>
          <div className="col-lg-3 col-md-6">
            <h5 className="mb-4">Get In Touch</h5>
            <p>
              <i className="fa fa-map-marker me-3" />
              123 Street, New York, USA
            </p>
            <p>
              <i className="fa fa-phone me-3" />
              +012 345 67890
            </p>
            <p>
              <i className="fa fa-envelope me-3" />
              info@example.com
            </p>
          </div>
          <div className="col-lg-3 col-md-6">
            <h5 className="mb-4">Our Services</h5>
            <a
              className="btn btn-link"
              href="/"
              style={{ textDecoration: "none" }}
            >
              Currency Wallet
            </a>
            <a
              className="btn btn-link"
              href="/"
              style={{ textDecoration: "none" }}
            >
              Currency Transaction
            </a>
            <a
              className="btn btn-link"
              href="/"
              style={{ textDecoration: "none" }}
            >
              Bitcoin Investment
            </a>
            <a
              className="btn btn-link"
              href="/"
              style={{ textDecoration: "none" }}
            >
              Token Sale
            </a>
          </div>
          <div className="col-lg-3 col-md-6">
            <h5 className="mb-4">Quick Links</h5>
            <a
              className="btn btn-link"
              href="/"
              style={{ textDecoration: "none" }}
            >
              About Us
            </a>
            <a
              className="btn btn-link"
              href="#contact"
              style={{ textDecoration: "none" }}
            >
              Contact Us
            </a>
            <a
              className="btn btn-link"
              href="/"
              style={{ textDecoration: "none" }}
            >
              Our Services
            </a>
            <a
              className="btn btn-link"
              href="/"
              style={{ textDecoration: "none" }}
            >
              Terms &amp; Condition
            </a>
          </div>
          <div className="col-lg-3 col-md-6">
            <h5 className="mb-4">Follow Us</h5>
            <div className="d-flex pt-2">
              <a
                className="btn btn-block me-2"
                href="/"
                style={{ color: "#16D5FF" }}
              >
                <i className="fab fs-3 fa-twitter" />
              </a>
              <a
                className="btn btn-block me-2"
                href="/"
                style={{ color: "#16D5FF" }}
              >
                <i className="fab fs-3 fa-facebook-f" />
              </a>
              <a
                className="btn btn-block me-2"
                href="/"
                style={{ color: "#16D5FF" }}
              >
                <i className="fab fs-3 fa-youtube" />
              </a>
              <a
                className="btn btn-block me-2"
                href="/"
                style={{ color: "#16D5FF" }}
              >
                <i className="fab fs-3 fa-linkedin-in" />
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid copyright">
        <div className="container">
          <div className="row">
            <div className="col-md-6 text-center text-md-start mb-3 mb-md-0">
              ©{" "}
              <Link to="/" style={{ textDecoration: "none" }}>
                Wemonitize 4U
              </Link>
              , All Right Reserved.
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;
