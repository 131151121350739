import React, { useState } from "react";
import Sidebar from "../../components/user/common/Sidebar";
import Topnav from "../../components/user/common/Topnav";
import Set from "../../components/user/dashboard/Set";

function Setting() {
  const [toogleNav, setToogleNav] = useState(false);

  return (
    <>
      <div className="main-container d-flex ">
        <Sidebar
          toogleNav={toogleNav}
          setToogleNav={() => setToogleNav(false)}
        />
        <div className="content">
          <Topnav
            toogleNav={toogleNav}
            setToogleNav={() => setToogleNav(true)}
          />
          <div className="dashboard-content">
            <Set />
          </div>
        </div>
      </div>
    </>
  );
}

export default Setting;
