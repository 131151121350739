import React, { useState, useEffect } from "react";
import { priceChartUpdate } from "../../api/Api";
import { toast } from "react-toastify";

function Modal({ clientData }) {
  const [crypto, setCrypto] = useState({
    coinName: "",
    amount: "",
    amountSymbol: "",
    fiatValue: "",
    changes: "",
  });

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (clientData) {
      setCrypto({
        coinName: clientData.coinName || "",
        amount: clientData.amount || "",
        amountSymbol: clientData.amountSymbol || "",
        fiatValue: clientData.fiatValue || "",
        changes: clientData.changes || "",
      });
    }
  }, [clientData]);

  const onChange = (e) => {
    setCrypto({ ...crypto, [e.target.name]: e.target.value });
  };

  const handleCrypto = async (e) => {
    e.preventDefault();
    setLoading(true);
    const result = await priceChartUpdate(crypto, clientData?._id);
    if (result && result.status === "success") {
      toast.success(result.message || "Successfully Updated");
    } else {
      toast.error(result.message || "Failed to Send Request.");
    }
    setLoading(false);
  };

  return (
    <div
      className="modal fade"
      id="exampleModal"
      tabIndex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-lg modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header">
            <h1 className="modal-title fs-5" id="exampleModalLabel">
              Update Crypto Data
            </h1>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body">
            <div className="container pb-4">
              <div className="row gutters">
                <div className="col-12 mt-2">
                  <form onSubmit={handleCrypto}>
                    <div className="row gutters">
                      <div className="col-md-6 col-12 mb-2">
                        <div className="form-group">
                          <label htmlFor="coinName">
                            <span className="fw-semibold">Key Coin</span>
                          </label>
                          <input
                            type="text"
                            className="form-control mt-1"
                            name="coinName"
                            value={crypto.coinName}
                            required
                            onChange={onChange}
                          />
                        </div>
                      </div>
                      <div className="col-md-6 col-12 mb-2">
                        <div className="form-group">
                          <label htmlFor="amount">
                            <span className="fw-semibold">Amount</span>
                          </label>
                          <input
                            type="number"
                            className="form-control mt-1"
                            name="amount"
                            required
                            onChange={onChange}
                            value={crypto.amount}
                          />
                        </div>
                      </div>
                      <div className="col-md-6 col-12 mb-2">
                        <div className="form-group">
                          <label htmlFor="amountSymbol">
                            <span className="fw-semibold">Symbol</span>
                          </label>
                          <input
                            type="text"
                            className="form-control mt-1"
                            name="amountSymbol"
                            required
                            onChange={onChange}
                            value={crypto.amountSymbol}
                          />
                        </div>
                      </div>
                      <div className="col-md-6 col-12 mb-2">
                        <div className="form-group">
                          <label htmlFor="fiatValue">
                            <span className="fw-semibold">Fiat Value</span>
                          </label>
                          <input
                            type="number"
                            className="form-control mt-1"
                            name="fiatValue"
                            value={crypto.fiatValue}
                            required
                            onChange={onChange}
                          />
                        </div>
                      </div>
                      <div className="col-md-6 col-12 mb-2">
                        <div className="form-group">
                          <label htmlFor="changes">
                            <span className="fw-semibold">Change</span>
                          </label>
                          <input
                            type="number"
                            className="form-control mt-1"
                            name="changes"
                            required
                            onChange={onChange}
                            value={crypto.changes}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row gutters">
                      <div className="col-12">
                        <div className="text-right mt-3 d-flex justify-content-end">
                          <button
                            type="button"
                            className="btn btn-secondary"
                            disabled={loading}
                            data-bs-dismiss="modal"
                          >
                            Cancel
                          </button>
                          <button
                            type="submit"
                            className="btn btn-info text-white ms-2"
                            disabled={loading}
                          >
                            {loading ? "Updating..." : "Update"}
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Modal;
