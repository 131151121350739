import React from "react";

function Sidebar(props) {
  return (
    <div
      className={`sidebar ${props.toogleNav ? "active" : ""} `}
      id="side_nav"
    >
      <div className="d-flex justify-content-end align-content-end ">
        <button
          className="btn d-md-none d-block close-btn px-1 py-0 text-white"
          onClick={props.setToogleNav}
        >
          {props.toogleNav ? (
            <i className="bi bi-caret-right-square fs-2 fw-bold "></i>
          ) : (
            ""
          )}
        </button>
      </div>
      <a href="/">
        <img
          className="img-fluid mt-3 bg-white rounded-5 ms-3"
          src="/assets/img/icon-1.png"
          alt="icon"
        />
      </a>
      <div className="header-box d-flex justify-content-between">
        <div className="fs-4">
          <h2 className="text-white mt-2 ms-3 fw-bold">We Monitize4u</h2>
        </div>
      </div>

      <ul className="list-unstyled px-2">
        <li className="d-flex align-items-center">
          <a
            href="/user/dashboard"
            className="text-decoration-none py-1 px-3 d-block d-flex align-items-center gap-2"
          >
            <i className="bi bi-house-door-fill fs-5"></i>{" "}
            <span className="fs-6 fw-semibold">Dashboard</span>
          </a>
        </li>
        <li className="d-flex align-items-center">
          <a
            href="/user/pricechart"
            className="text-decoration-none py-1 px-3 d-block d-flex align-items-center gap-2"
          >
            <i className="bi bi-graph-down fs-5"></i>{" "}
            <span className="fs-6 fw-semibold">Price Chart</span>
          </a>
        </li>
        <li className="d-flex align-items-center">
          <a
            href="/user/verify"
            className="text-decoration-none py-1 px-3  d-block d-flex align-items-center gap-2 d-flex align-items-center gap-2"
          >
            <i className="bi bi-patch-check fs-5"></i>{" "}
            <span className="fs-6 fw-semibold">Verification</span>
          </a>
        </li>
        <li className="d-flex align-items-center">
          <a
            href="/user/subscription"
            className="text-decoration-none py-1 px-3  d-block d-flex align-items-center gap-2"
          >
            <i className="bi bi-bookmarks fs-5"></i>
            <span className="fs-6 fw-semibold">Subscription</span>
          </a>
        </li>
        <li className="d-flex align-items-center">
          <a
            href="/user/valueunlock"
            className="text-decoration-none py-1 px-3  d-block d-flex align-items-center gap-2"
          >
            <i className="bi bi-unlock fs-5"></i>
            <span className="fs-6 fw-semibold">Value Unlock</span>
          </a>
        </li>
        <li className="d-flex align-items-center">
          <a
            href="/user/withdraw"
            className="text-decoration-none py-1 px-3  d-block d-flex align-items-center gap-2"
          >
            <i className="bi bi-lock fs-5"></i>
            <span className="fs-6 fw-semibold">Withdraw</span>
          </a>
        </li>
        <li className="d-flex align-items-center">
          <a
            href="/user/virtualcalculator"
            className="text-decoration-none py-1 px-3  d-block d-flex align-items-center gap-2"
          >
            <i className="bi bi-calculator-fill fs-5"></i>
            <span className="fs-6 fw-semibold">Virtual Calculator</span>
          </a>
        </li>
        <li className="d-flex align-items-center">
          <a
            href="/user/setting"
            className="text-decoration-none px-3  d-block d-flex align-items-center gap-2"
          >
            <i className="bi bi-gear fs-5"></i>
            <span className="fs-6 fw-semibold">Settings</span>
          </a>
        </li>
      </ul>
      <hr className="text-white fw-bold mx-2" />
    </div>
  );
}

export default Sidebar;
