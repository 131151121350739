import React from "react";
import { useState } from "react";
import { toast } from "react-toastify";
import { contactForm } from "../../api/Api";

function Contact() {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const result = await contactForm({ name, email, subject, message });
      if (result.status === "success") {
        toast.success(result.message || "Form Submit Successful!");
        setEmail("");
        setName("");
        setSubject("");
        setMessage("");
      } else {
        toast.error(result.message || "Failed To Submit.");
      }
    } catch (error) {
      toast.error(
        "An error occurred during form submission. Please try again."
      );
    } finally {
      setLoading(false);
    }
  };
  return (
    <div className="container-xxl py-5 min-h-100" id="contact">
      <div className="container">
        <div className="row g-5 mb-5 wow fadeInUp" data-wow-delay="0.1s">
          <div className="col-lg-6">
            <h1 className="display-4 fw-semibold">Contact Us</h1>
            <p className="fs-5 mb-0" style={{ color: "#16D5FF" }}>
              If You Have Any Query, Please Contact Us
            </p>
          </div>
          <div className="col-lg-6 text-lg-end">
            <a
              href="##"
              className="btn btn-block py-3 px-4 text-white"
              style={{ backgroundColor: "#16D5FF", textDecoration: "none" }}
            >
              Say Hello
            </a>
          </div>
        </div>
        <div className="row g-5">
          <div className="col-lg-5 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
            <p className="mb-2">Our office:</p>
            <h4>123 Street, New York, USA</h4>
            <hr className="w-100" />
            <p className="mb-2">Call us:</p>
            <h4>+012 345 6789</h4>
            <hr className="w-100" />
            <p className="mb-2">Mail us:</p>
            <h4>info@example.com</h4>
            <hr className="w-100" />
            <p className="mb-2">Follow us:</p>
            <div className="d-flex pt-2">
              <a
                className="btn btn-square btn-block rounded-circle me-2"
                href="##"
                style={{ color: "#16D5FF" }}
              >
                <i className="fab fs-3 fa-twitter" />
              </a>
              <a
                className="btn btn-square btn-block rounded-circle me-2"
                href="##"
                style={{ color: "#16D5FF" }}
              >
                <i className="fab fs-3 fa-facebook-f" />
              </a>
              <a
                className="btn btn-square btn-block rounded-circle me-2"
                href="##"
                style={{ color: "#16D5FF" }}
              >
                <i className="fab fs-3 fa-youtube" />
              </a>
              <a
                className="btn btn-square btn-block rounded-circle me-2"
                href="##"
                style={{ color: "#16D5FF" }}
              >
                <i className="fab fs-3 fa-linkedin-in" />
              </a>
            </div>
          </div>
          <div className="col-lg-7 col-md-6 wow fadeInUp" data-wow-delay="0.5s">
            <p className="mb-4">
              The contact form is currently inactive. Get a functional and
              working contact form with Ajax &amp; PHP in a few minutes. Just
              copy and paste the files, add a little code and you're done.{" "}
              <a
                href="https://htmlcodex.com/contact-form"
                style={{ color: "#16D5FF" }}
              >
                Download Now
              </a>
              .
            </p>
            <form onSubmit={handleSubmit}>
              <div className="row g-3">
                <div className="col-md-6">
                  <div className="form-floating">
                    <input
                      type="text"
                      className="form-control"
                      name="name"
                      value={name}
                      id="name"
                      placeholder="Your Name"
                      required
                      onChange={(e) => setName(e.target.value)}
                    />
                    <label htmlFor="name">Your Name</label>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-floating">
                    <input
                      type="email"
                      className="form-control"
                      id="email"
                      name="email"
                      value={email}
                      required
                      onChange={(e) => setEmail(e.target.value)}
                      placeholder="Your Email"
                    />
                    <label htmlFor="email">Your Email</label>
                  </div>
                </div>
                <div className="col-12">
                  <div className="form-floating">
                    <input
                      type="text"
                      className="form-control"
                      id="subject"
                      name="subject"
                      value={subject}
                      required
                      onChange={(e) => setSubject(e.target.value)}
                      placeholder="Subject"
                    />
                    <label htmlFor="subject">Subject</label>
                  </div>
                </div>
                <div className="col-12">
                  <div className="form-floating">
                    <textarea
                      className="form-control"
                      placeholder="Leave a message here"
                      name="message"
                      value={message}
                      required
                      onChange={(e) => setMessage(e.target.value)}
                      id="message"
                      style={{ height: 100 }}
                      defaultValue={""}
                    />
                    <label htmlFor="message">Message</label>
                  </div>
                </div>
                <div className="col-12">
                  <button
                    className="btn btn-block py-3 px-4 text-white"
                    disabled={loading}
                    style={{ backgroundColor: "#16D5FF" }}
                  >
                    {loading ? "LOADING..." : "Send Message"}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Contact;
