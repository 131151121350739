import React, { useState } from "react";
import Header from "../user/common/Header";
import { toast } from "react-toastify";
import { priceChart } from "../../api/Api";
import ChartTable from "./ChartTable";

function ChartForm() {
  const [crypto, setCrypto] = useState({
    coinName: "",
    amount: "",
    amountSymbol: "",
    fiatValue: "",
    changes: "",
  });

  const [loading, setLoading] = useState(false);

  const onChange = (e) => {
    setCrypto({ ...crypto, [e.target.name]: e.target.value });
  };

  const handleCrypto = async (e) => {
    e.preventDefault();
    setLoading(true);
    const result = await priceChart(crypto);
    if (result && result.status === "success") {
      toast.success(result.message || "Successfully Created");
      window.location.reload();
      setCrypto({
        coinName: "",
        amount: "",
        amountSymbol: "",
        fiatValue: "",
        changes: "",
      });
    } else {
      toast.error(result.message || "Failed to Send Request.");
    }
    setLoading(false);
  };

  return (
    <>
      <Header title="Price Chart" />
      <div
        className="container"
        data-wow-duration="1s"
        data-wow-delay="0.1s"
        data-wow-offset={0}
        style={{
          visibility: "visible",
          animationDuration: "1s",
          animationDelay: "0.1s",
          animationName: "fadeInUp",
        }}
      >
        <div className="container pb-4">
          <div className="row gutters">
            <div className="col-12 mt-2">
              <div className="card h-100">
                <div className="card-body">
                  <form onSubmit={handleCrypto}>
                    <div className="row gutters">
                      <div className="col-md-6 col-12 mb-2">
                        <div className="form-group">
                          <label htmlFor="coinName">
                            <span className="fw-semibold">Platform</span>
                          </label>
                          <input
                            type="text"
                            className="form-control mt-1"
                            name="coinName"
                            value={crypto.coinName}
                            required
                            onChange={onChange}
                          />
                        </div>
                      </div>
                      <div className="col-md-6 col-12 mb-2">
                        <div className="form-group">
                          <label htmlFor="amount">
                            <span className="fw-semibold">Amount</span>
                          </label>
                          <input
                            type="number"
                            className="form-control mt-1"
                            name="amount"
                            required
                            onChange={onChange}
                            value={crypto.amount}
                          />
                        </div>
                      </div>
                      <div className="col-md-6 col-12 mb-2">
                        <div className="form-group">
                          <label htmlFor="amountSymbol">
                            <span className="fw-semibold">Symbol</span>
                          </label>
                          <input
                            type="text"
                            className="form-control mt-1"
                            name="amountSymbol"
                            required
                            onChange={onChange}
                            value={crypto.amountSymbol}
                          />
                        </div>
                      </div>
                      <div className="col-md-6 col-12 mb-2">
                        <div className="form-group">
                          <label htmlFor="fiatValue">
                            <span className="fw-semibold">Fiat Value</span>
                          </label>
                          <input
                            type="number"
                            className="form-control mt-1"
                            name="fiatValue"
                            value={crypto.fiatValue}
                            required
                            onChange={onChange}
                          />
                        </div>
                      </div>
                      <div className="col-md-6 col-12 mb-2">
                        <div className="form-group">
                          <label htmlFor="changes">
                            <span className="fw-semibold">Change</span>
                          </label>
                          <input
                            type="number"
                            className="form-control mt-1"
                            name="changes"
                            required
                            onChange={onChange}
                            value={crypto.changes}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row gutters">
                      <div className="col-12">
                        <div className="text-right mt-3 d-flex justify-content-end">

                          <button
                            type="submit"
                            className="btn btn-info text-white ms-2"
                            disabled={loading}
                          >
                            {loading ? "Adding..." : "Add"}
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ChartTable />
      </div>
    </>
  );
}

export default ChartForm;
