import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { logoutUser } from "../../../redux/actions/logoutUser";
import { useNavigate } from "react-router-dom";



function Topnav(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const tokken = useSelector((state) => state.userData.token);
  const userData = useSelector((state) => state.userData.user);

  useEffect(() => {
    if (tokken === "" || userData !=="admin" ) {
      navigate("/admin/login"); 
    }
  }, [tokken,userData, navigate]);

  const handleLogout = () => {
    dispatch(logoutUser());
    navigate("/admin/login");
  };

  return (
    <header
      className="d-flex flex-wrap align-items-end justify-content-start py-3 mb-4 pe-5"
      style={{ backgroundColor: "#16D5FF" }}
    >
      <div>
        <button
          className="btn px-1 py-0 toggle-btn d-md-none d-block me-2"
          onClick={props.setToogleNav}
        >
          {props.toogleNav ? (
            ""
          ) : (
            <i className="bi bi-list fs-2 fw-bold text-white"></i>
          )}
        </button>
      </div>
      <div className="ms-auto">
        <button
          type="button"
          className="btn btn-block me-4"
          style={{ backgroundColor: "white" }}
          onClick={handleLogout}
        >
          Logout
        </button>
      </div>
    </header>
  );
}

export default Topnav;
