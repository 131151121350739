import React, { useEffect, useState } from "react";
import { getAllUsersPriceChart, DeleteChartValue } from "../../api/Api";
import { toast } from "react-toastify";
import Spinner from "../homeComp/commonComp/Spinner";
import Modal from "./Modal";
import DataTable from "react-data-table-component";

function ChartTable() {
  const [clientRows, setClientRows] = useState([]);
  const [filteredRows, setFilteredRows] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedClient, setSelectedClient] = useState(null);
  const [search, setSearch] = useState("");

  useEffect(() => {
    const fetchClients = async () => {
      setLoading(true);
      try {
        const res = await getAllUsersPriceChart();
        if (res.status === "success") {
          setClientRows(res.data);
          setFilteredRows(res.data); 
        } else {
          toast.error("Failed to fetch Data.");
        }
      } catch (error) {
        toast.error("An error occurred while fetching Data.");
      } finally {
        setLoading(false);
      }
    };

    fetchClients();
  }, []);

  useEffect(() => {
    const filteredData = clientRows.filter(
      (item) =>
        item.coinName.toLowerCase().includes(search.toLowerCase()) ||
        item.amountSymbol.toLowerCase().includes(search.toLowerCase()) ||
        item.fiatValue.toString().includes(search.toLowerCase()) ||
        item.changes.toString().includes(search.toLowerCase())
    );
    setFilteredRows(filteredData);
  }, [search, clientRows]);

  const handleDelete = async (id) => {
    setLoading(true);
    try {
      const result = await DeleteChartValue(id);
      if (result.status === "success") {
        toast.success(result.message || "Deleted successfully!");
        const res = await getAllUsersPriceChart();
        if (res.status === "success") {
          setClientRows(res.data);
        }
      } else {
        toast.error(result.message || "Failed to delete.");
      }
    } catch (error) {
      toast.error("An error occurred during deletion. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const handleUpdateClick = (client) => {
    setSelectedClient(client);
  };

  if (loading) {
    return <Spinner />;
  }

  const columns = [
    {
      name: "#",
      selector: (row, index) => index + 1,
      sortable: true,
      width: "60px",
    },
    {
      name: "Platform",
      selector: (row) => row.coinName,
      sortable: true,
      width: "220px",
    },
    {
      name: "Amount",
      selector: (row) => `${row.amountSymbol}${row.amount}`,
      sortable: true,
      width: "220px",
    },
    {
      name: "Fiat Value",
      selector: (row) => `${row.amountSymbol}${row.fiatValue}`,
      sortable: true,
      width: "220px",
    },
    {
      name: "Change%",
      selector: (row) => `${row.changes}%`,
      sortable: true,
      width: "220px",
      cell: (row) => (
        <span
          className={`${
            parseFloat(row.changes) < 0 ? "text-danger" : "text-success"
          } text-uppercase`}
        >
          {row.changes}%
        </span>
      ),
    },
    {
      name: "Action",
      cell: (row) => (
        <div className="d-flex gap-2">
          <button
            className="btn text-danger"
            onClick={() => handleDelete(row._id)}
          >
            <i className="bi bi-trash"></i>
          </button>
          <button
            data-bs-toggle="modal"
            data-bs-target="#exampleModal"
            className="btn btn-secondary btn-sm text-nowrap"
            onClick={() => handleUpdateClick(row)}
          >
            Update
          </button>
        </div>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
  ];

  return (
    <>
      <div className="container">
        <div className="row gutters">
          <div className="col-md-12">
            <div className="card h-100">
              <div className="card-body">
                <div className="mb-3 w-100 d-flex justify-content-end">
                  <input
                    type="text"
                    className="form-control w-25"
                    placeholder="Search..."
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                  />
                </div>
                <DataTable
                  columns={columns}
                  data={filteredRows}
                  pagination
                  highlightOnHover
                  defaultSortField="coinName"
                  striped
                />
              </div>
            </div>
          </div>
        </div>
      </div>
<Modal clientData={selectedClient} />
    </>
  );
}

export default ChartTable;
