import React, { useState } from "react";
import Sidebar from "./common/Sidebar";
import Topnav from "./common/Topnav";
import CryptoWith from "../../components/admin/CryptoWith";

function CryptoWithdraw() {
  const [toogleNav, setToogleNav] = useState(false);
  return (
    <>
      <div className="main-container d-flex">
        <Sidebar
          toogleNav={toogleNav}
          setToogleNav={() => setToogleNav(false)}
        />
        <div className="content">
          <Topnav
            toogleNav={toogleNav}
            setToogleNav={() => setToogleNav(true)}
          />
          <div className="dashboard-content">
            <CryptoWith />
          </div>
        </div>
      </div>
    </>
  );
}

export default CryptoWithdraw;
