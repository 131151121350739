import { ActionTypes } from "../constants/actionTypes";

const initialState = {
  user: [],
  token: "",
};

export const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.SET_USER:
      return {
        ...state,
        user: action.payload,
      };
    case ActionTypes.SET_TOKEN:
      return {
        ...state,
        token: action.payload,
      };
    case ActionTypes.LOGOUT:
      return {
        ...state,
        user: [],
        token: "",
      };
    default:
      return state;
  }
};
