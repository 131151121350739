import React, { useState } from "react";
import { useSelector } from "react-redux";
import { userBankWithdraw } from "../../../api/Api";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";

function BankWithdraw() {
  const userToken = useSelector((state) => state.userData.token);
  const userData = useSelector((state) => state.userData.user);
  const kycStatus = useSelector(
    (state) => state.userData.user.kycDoc?.kycStatus
  );

  const [credentials, setCredentials] = useState({
    bankName: "",
    bankAddress: "",
    swiftCode: "",
    accountNumber: "",
    ifscCode: "",
    routingNumber: "",
    amount: "",
  });

  const [loading, setLoading] = useState(false);

  const onChange = (e) => {
    setCredentials({ ...credentials, [e.target.name]: e.target.value });
  };

  const handleBank = async (e) => {
    e.preventDefault();
    setLoading(true);
    const result = await userBankWithdraw(credentials, userToken);
    if (result && result.status === "success") {
      toast.success(result.message || "Withdraw Request Successfully Created");
      setCredentials({
        bankName: "",
        bankAddress: "",
        swiftCode: "",
        accountNumber: "",
        ifscCode: "",
        routingNumber: "",
        amount: "",
      });
    } else {
      toast.error(result.message || "Failed to Make Request.");
    }
    setLoading(false);
  };

  if (kycStatus !== "1") {
    return (
      <div className="mt-5 d-flex flex-column align-items-center justify-content-center">
        <div className="text-danger mt-3">
          KYC approval is required to access withdrawal options.
        </div>
        <div>
          <Link to="/user/verify" className="btn btn-info mt-5 text-white">
            Verify Your KYC
          </Link>
        </div>
      </div>
    );
  }

  return (
    <>
      <div className="row">
        <div className="col-lg-12 mt-2">
          <div className="card pb-2 h-100">
            <div className="card-body">
              <h4 className="mb-3 text-info">Your Bank Details</h4>
              <form onSubmit={handleBank}>
                <div className="row">
                  <div className="col-lg-6 col-md-6 col-sm-12 mb-2">
                    <div className="form-group">
                      <label htmlFor="fullName">
                        <span className="fw-semibold">Full Name</span>
                      </label>
                      <input
                        type="text"
                        className="form-control mt-1"
                        name="fullName"
                        value={userData.fullName}
                        placeholder="Enter Full Name"
                        disabled
                      />
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-12 mb-2">
                    <div className="form-group">
                      <label htmlFor="bankname">
                        <span className="fw-semibold">Bank Name</span>
                      </label>
                      <input
                        type="text"
                        className="form-control mt-1"
                        name="bankName"
                        onChange={onChange}
                        placeholder="Enter Bank Name"
                        value={credentials.bankName}
                        required
                      />
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-12 mb-2">
                    <div className="form-group">
                      <label htmlFor="ifscCode">
                        <span className="fw-semibold">IFSC Code</span>
                      </label>
                      <input
                        type="text"
                        className="form-control mt-1"
                        name="ifscCode"
                        onChange={onChange}
                        placeholder="Enter IFSC Code"
                        value={credentials.ifscCode}
                        required
                      />
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-12 mb-2">
                    <div className="form-group">
                      <label htmlFor="swiftCode">
                        <span className="fw-semibold">Swift Code</span>
                      </label>
                      <input
                        type="text"
                        className="form-control mt-1"
                        name="swiftCode"
                        onChange={onChange}
                        placeholder="Enter Swift Code"
                        value={credentials.swiftCode}
                        required
                      />
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-12 mb-2">
                    <div className="form-group">
                      <label htmlFor="bankAddress">
                        <span className="fw-semibold">Branch Location</span>
                      </label>
                      <input
                        type="text"
                        className="form-control mt-1"
                        name="bankAddress"
                        onChange={onChange}
                        placeholder="Enter Branch Location"
                        value={credentials.bankAddress}
                        required
                      />
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-12 mb-2">
                    <div className="form-group">
                      <label htmlFor="accountNumber">
                        <span className="fw-semibold">Bank Account Number</span>
                      </label>
                      <input
                        type="text"
                        className="form-control mt-1"
                        name="accountNumber"
                        onChange={onChange}
                        placeholder="Enter Account Number"
                        value={credentials.accountNumber}
                        required
                      />
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-12 mb-2">
                    <div className="form-group">
                      <label htmlFor="routingNumber">
                        <span className="fw-semibold">
                          Bank Routing/Sort Number
                        </span>
                      </label>
                      <input
                        type="text"
                        className="form-control mt-1"
                        name="routingNumber"
                        onChange={onChange}
                        placeholder="Enter Bank Routing"
                        value={credentials.routingNumber}
                        required
                      />
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-12 mb-2">
                    <div className="form-group">
                      <label htmlFor="amount">
                        <span className="fw-semibold">Enter Amount</span>
                      </label>
                      <input
                        type="number"
                        className="form-control mt-1"
                        name="amount"
                        onChange={onChange}
                        placeholder="Enter Amount"
                        value={credentials.amount}
                        required
                      />
                    </div>
                  </div>
                </div>
                <div className="text-right mt-3 d-flex justify-content-end">
                  <button
                    disabled={loading}
                    type="submit"
                    className="btn btn-info ms-2 text-white"
                  >
                    {loading ? "Processing..." : "Withdraw"}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default BankWithdraw;
