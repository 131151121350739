import React, { useEffect, useState } from "react";
import Header from "../user/common/Header";
import {
  ApprovedStatusKyc,
  DisapprovedStatusKyc,
  getAllUsersById,
} from "../../api/Api";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";
import Spinner from "../homeComp/commonComp/Spinner";

const DEFAULT_PROFILE_PICTURE =
  "https://bootdey.com/img/Content/avatar/avatar7.png";

function UserPage() {
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [userData, setUserData] = useState(null);
  const [imageSrc, setImageSrc] = useState(DEFAULT_PROFILE_PICTURE);

  useEffect(() => {
    const fetchUserData = async () => {
      setLoading(true);
      try {
        const res = await getAllUsersById(id);
        if (res.status === "success") {
          setUserData(res.data);
        } else {
          toast.error("Failed to fetch user data.");
        }
      } catch (error) {
        toast.error("An error occurred while fetching user data.");
      } finally {
        setLoading(false);
      }
    };
    fetchUserData();
  }, [id]);

  const handleStatusApproved = async (id) => {
    setLoading(true);
    try {
      const result = await ApprovedStatusKyc(id);
      if (result.status === "success") {
        toast.success(result.message || "Status updated successfully!");
        const res = await getAllUsersById();
        if (res.status === "success") {
        }
      } else {
        toast.error(result.message || "Failed to update status.");
      }
    } catch (error) {
      toast.error("An error occurred during status update. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const handleStatusReject = async (id) => {
    setLoading(true);
    try {
      const result = await DisapprovedStatusKyc(id);
      if (result.status === "success") {
        toast.error(result.message || "Status updated successfully!");
        const res = await getAllUsersById();
        if (res.status === "success") {
        }
      } else {
        toast.error(result.message || "Failed to update status.");
      }
    } catch (error) {
      toast.error("An error occurred during status update. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (userData?.profilePicture && userData.profilePicture.length > 0) {
      setImageSrc(userData.profilePicture);
    } else {
      setImageSrc(DEFAULT_PROFILE_PICTURE);
    }
  }, [userData]);

  if (loading) {
    return <Spinner />;
  }

  if (!userData) {
    return <div className="container">No user data available.</div>;
  }
  return (
    <>
      <Header title="User Details" />
      <div className="container section-spacing">
        <div className="col-xxl-12">
          <div className="card mt-xxl-n5">
            <div className="card-header">
              <div className="row">
                <div className="col-lg-4 col-6">
                  <h4 className="card-title mb-0 fw-semibold">Profile</h4>
                </div>
              </div>
            </div>
            <div className="card-body p-4">
              <div className="row d-flex align-items-center">
                <div className="col-lg-4 col-12 text-center mb-4 mb-md-0">
                  <img
                    src={imageSrc}
                    width={"50%"}
                    alt="Profile"
                    className="mx-auto d-block rounded-3"
                  />
                </div>
                <div className="col-lg-4">
                  <div className="row mb-3">
                    <div className="col-5">
                      <small className="fw-bold">Full Name</small>
                    </div>
                    <div className="col-7">
                      <small className="text-danger">
                        {userData?.fullName || "N/A"}
                      </small>
                    </div>
                  </div>
                  <div className="row mb-3">
                    <div className="col-5">
                      <small className="fw-bold">Phone</small>
                    </div>
                    <div className="col-7">
                      <small className="text-muted">
                        {userData?.phone || "N/A"}
                      </small>
                    </div>
                  </div>
                  <div className="row mb-3">
                    <div className="col-5">
                      <small className="fw-bold">Address</small>
                    </div>
                    <div className="col-7">
                      <small className="text-muted">
                        {userData?.address || "N/A"}
                      </small>
                    </div>
                  </div>
                  <div className="row mb-3">
                    <div className="col-5">
                      <small className="fw-bold">City</small>
                    </div>
                    <div className="col-7">
                      <small className="text-muted">
                        {userData?.city || "N/A"}
                      </small>
                    </div>
                  </div>
                  <div className="row mb-3">
                    <div className="col-5">
                      <small className="fw-bold">Country</small>
                    </div>
                    <div className="col-7">
                      <small className="text-muted">
                        {userData?.country || "N/A"}
                      </small>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="row mb-3">
                    <div className="col-5">
                      <small className="fw-bold">Email</small>
                    </div>
                    <div className="col-7">
                      <small className="text-danger">
                        {userData?.email || "N/A"}
                      </small>
                    </div>
                  </div>
                  <div className="row mb-3">
                    <div className="col-5">
                      <small className="fw-bold">Apartment</small>
                    </div>
                    <div className="col-7">
                      <small className="text-muted">
                        {userData?.apartment || "N/A"}
                      </small>
                    </div>
                  </div>
                  <div className="row mb-3">
                    <div className="col-5">
                      <small className="fw-bold">Street Address</small>
                    </div>
                    <div className="col-7">
                      <small className="text-muted">
                        {userData?.streetAddress || "N/A"}
                      </small>
                    </div>
                  </div>
                  <div className="row mb-3">
                    <div className="col-5">
                      <small className="fw-bold">State</small>
                    </div>
                    <div className="col-7">
                      <small className="text-muted">
                        {userData?.state || "N/A"}
                      </small>
                    </div>
                  </div>
                  <div className="row mb-3">
                    <div className="col-5">
                      <small className="fw-bold">Zip Code</small>
                    </div>
                    <div className="col-7">
                      <small className="text-muted">
                        {userData?.zipCode || "N/A"}
                      </small>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="card mb-5">
            <div className="card-body">
              <div className="row">
                <div className="col-lg-12 col-12">
                  <h4 className="card-title fw-semibold">Documents</h4>
                </div>
                <div className="row">
                  {userData?.kycDoc?.identity ||
                  userData?.kycDoc?.address ||
                  userData?.kycDoc?.localBill ? (
                    <>
                      {userData.kycDoc.localBill && (
                        <div className="col-md-4 text-center">
                          <a
                            className="text-decoration-none text-info"
                            href={userData.kycDoc.localBill}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <i
                              className="bi bi-file-break-fill"
                              style={{ fontSize: "200px" }}
                            ></i>
                            <div>
                              <h5 className="mt-0 ">Local Bill</h5>
                            </div>
                          </a>
                        </div>
                      )}
                      {userData.kycDoc.identity && (
                        <div className="col-md-4 text-center">
                          <a
                            className="text-decoration-none text-info"
                            href={userData.kycDoc.identity}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <i
                              className="bi bi-file-break-fill"
                              style={{ fontSize: "200px" }}
                            ></i>
                            <div>
                              <h5 className="mt-0">Identity</h5>
                            </div>
                          </a>
                        </div>
                      )}
                      {userData.kycDoc.address && (
                        <div className="col-md-4 text-center">
                          <a
                            className="text-decoration-none text-info"
                            href={userData.kycDoc.address}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <i
                              className="bi bi-file-break-fill"
                              style={{ fontSize: "200px" }}
                            ></i>
                            <div>
                              <h5 className="mt-0">Address</h5>
                            </div>
                          </a>
                        </div>
                      )}
                      <div className="row">
                        <div className="col-md-12 d-flex justify-content-end align-items-center">
                          <button
                            type="button"
                            className="btn btn-danger text-white ms-2 mt-4"
                            onClick={() => handleStatusReject(userData.kyc)}
                          >
                            Reject KYC
                          </button>
                          <button
                            type="button"
                            className="btn btn-info text-white ms-2 mt-4"
                            onClick={() => handleStatusApproved(userData.kyc)}
                          >
                            Verify KYC
                          </button>
                        </div>
                      </div>
                    </>
                  ) : (
                    <div className="col-lg-12 text-center">
                      <p className="fs-3 text-muted">KYC Documents Not Sumbitted By the User</p>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default UserPage;
