import React, { useRef, useState, useEffect } from "react";
import { toast } from "react-toastify";
import { singleImageUpload, updateUserData } from "../../../api/Api";
import { setUser } from "../../../redux/actions/userProfile";
import { useDispatch } from "react-redux";

const DEFAULT_PROFILE_PICTURE = "https://bootdey.com/img/Content/avatar/avatar7.png";

function ProfileImg({ userData, userToken, ...props }) {
  const [loading, setLoading] = useState(false);
  const [profilePicture, setProfilePicture] = useState(DEFAULT_PROFILE_PICTURE);

  useEffect(() => {
    setProfilePicture(
      userData.profilePicture && userData.profilePicture.length > 0 ? userData.profilePicture : DEFAULT_PROFILE_PICTURE
    );
  }, [userData]);

  const fileInputRef = useRef(null);
  const dispatch = useDispatch();
  const [imageSrc, setImageSrc] = useState(profilePicture);

  useEffect(() => {
    setImageSrc(profilePicture);
  }, [profilePicture]);

  const handleEditClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    if (file) {
      setLoading(true);
      const reader = new FileReader();
      reader.onload = () => {
        setImageSrc(reader.result);
      };
      reader.readAsDataURL(file);
      const formdata = new FormData();
      formdata.append("image", file);
      const result = await singleImageUpload(formdata);
      if (result.status === "success") {
        const imageUrl = result.data;
        updateUserProfile(imageUrl);
      } else {
        toast.error("Fail To Upload Image");
      }
      setLoading(false);
    }
  };

  const updateUserProfile = async (imageUrl) => {
    const result = await updateUserData(
      { profilePicture: imageUrl },
      userToken
    );
    if (result.status === "success") {
      toast.success("Profile Picture Uploaded Successfully");
      dispatch(setUser(result.data));
    } else {
      toast.error(result.message);
    }
  };

  return (
    <div className="user-avatar d-flex flex-column">
      <div>
        <img className="img-avatar" src={imageSrc} alt="profile" />
      </div>
      <div className="d-none">
        <input type="file" ref={fileInputRef} onChange={handleFileChange} />
      </div>
      <button
        className="btn btn-sm mt-3 text-info"
        onClick={handleEditClick}
        disabled={loading}
      >
        {loading ? "Wait" : <i className="fa fa-pen" />}
      </button>
    </div>
  );
}

export default ProfileImg;
