import React, { useEffect, useState } from "react";
import { getAllContactus } from "../../api/Api";
import { toast } from "react-toastify";
import Spinner from "../homeComp/commonComp/Spinner";
import DataTable from "react-data-table-component";
import Header from "../user/common/Header";

function Support() {
  const [clientRows, setClientRows] = useState([]);
  const [filteredRows, setFilteredRows] = useState([]);
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState("");

  useEffect(() => {
    const fetchClients = async () => {
      setLoading(true);
      try {
        const res = await getAllContactus();
        if (res.status === "success") {
          setClientRows(res.data);
          setFilteredRows(res.data);
        } else {
          toast.error("Failed to fetch Data.");
        }
      } catch (error) {
        toast.error("An error occurred while fetching Data.");
      } finally {
        setLoading(false);
      }
    };

    fetchClients();
  }, []);

  useEffect(() => {
    const filteredData = clientRows.filter(
      (item) =>
        item.name.toLowerCase().includes(search.toLowerCase()) ||
        item.email.toLowerCase().includes(search.toLowerCase()) ||
        item.subject.toString().includes(search.toLowerCase()) ||
        item.message.toString().includes(search.toLowerCase())
    );
    setFilteredRows(filteredData);
  }, [search, clientRows]);

  if (loading) {
    return <Spinner />;
  }

  const columns = [
    {
      name: "#",
      selector: (row, index) => index + 1,
      sortable: true,
      width: "100px",
    },
    {
      name: "Name",
      selector: (row) => row.name,
      sortable: true,
      width: "150px",
    },
    {
      name: "Email",
      selector: (row) => row.email,
      sortable: true,
      width: "250px",
    },
    {
      name: "Subject",
      selector: (row) => row.subject,
      sortable: true,
      width: "250px",
    },
    {
      name: "Message",
      selector: (row) => row.message,
      sortable: true,
      width: "550px",
      cell: (row) => (
        <div style={{ whiteSpace: 'normal', wordWrap: 'break-word', maxWidth: '500px' }}>
          {row.message}
        </div>
      ),
    },

    {
      name: "Created At",
      selector: (row) => new Date(row.createdAt).toLocaleDateString(),
      sortable: true,
    },
  ];

  return (
    <>
      <Header title="Support" />
      <div
        className="container"
        data-wow-duration="1s"
        data-wow-delay="0.1s"
        data-wow-offset={0}
        style={{
          visibility: "visible",
          animationDuration: "1s",
          animationDelay: "0.1s",
          animationName: "fadeInUp",
        }}
      >
        <div className="container">
          <div className="row gutters">
            <div className="col-md-12">
              <div className="card h-100">
                <div className="card-body">
                  <div className="mb-3 w-100 d-flex justify-content-end">
                    <input
                      type="text"
                      className="form-control w-25"
                      placeholder="Search..."
                      value={search}
                      onChange={(e) => setSearch(e.target.value)}
                    />
                  </div>
                  <DataTable
                    columns={columns}
                    data={filteredRows}
                    pagination
                    highlightOnHover
                    defaultSortField="coinName"
                    striped
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Support;
