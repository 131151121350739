import React, { useEffect, useState } from "react";

function TransModal({ transaction, onClose }) {
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    if (transaction) {
      setShowModal(true);
    } else {
      setShowModal(false);
    }
  }, [transaction]);

  if (!transaction || !showModal) return null;

  const isCrypto = !!transaction.btc || !!transaction.usdt;

  const getStatusText = (status) => {
    switch (status) {
      case -1:
        return { text: "Rejected", className: "badge text-bg-danger" };
      case 0:
        return { text: "Pending", className: "badge text-bg-warning" };
      case 1:
        return { text: "Success", className: "badge text-bg-success" };
      default:
        return { text: "Unknown", className: "badge-secondary" };
    }
  };

  const status = getStatusText(transaction.status);

  return (
    <div
      className="modal d-block"
      style={{ backgroundColor: "rgba(0,0,0,0.5)" }}
      onClick={onClose}
    >
      <div
        className="modal-dialog modal-lg modal-dialog-centered"
        onClick={(e) => e.stopPropagation()} 
      >
        <div className="modal-content">
          <div className="modal-header">
            <h1 className="modal-title fs-5">Transaction Details</h1>
            <button
              type="button"
              className="btn-close"
              aria-label="Close"
              onClick={() => {
                setShowModal(false);
                onClose();
              }}
            ></button>
          </div>
          <div className="modal-body">
            <div className="container pb-4">
              <div className="row">
                <div className="col-12 mt-2">
                  <h5>Transaction ID: {transaction._id}</h5>
                  <p>
                    <strong>Status:</strong>{" "}
                    <span className={`badge ${status.className}`}>
                      {status.text}
                    </span>
                  </p>
                  <p><strong>Date:</strong> {new Date(transaction.createdAt).toLocaleString()}</p>
                  <p><strong>Method:</strong> {isCrypto ? "Crypto" : "Bank Transfer"}</p>
                </div>
              </div>
              {isCrypto ? (
                <div className="row">
                  <div className="col-md-6">
                    <p><strong> Address:</strong> {transaction.btc}</p>
                  </div>
                  <div className="col-md-6">
                    <p><strong>Crypto Type:</strong> {transaction.usdt}</p>
                  </div>
                  <div className="col-12">
                    <p><strong>Amount:</strong> ${transaction.amount}</p>
                  </div>
                </div>
              ) : (
                <div className="row">
                  <div className="col-md-6">
                    <p><strong>Bank Name:</strong> {transaction.bankName}</p>
                    <p><strong>Account Number:</strong> {transaction.accountNumber}</p>
                    <p><strong>IFSC Code:</strong> {transaction.ifscCode}</p>
                  </div>
                  <div className="col-md-6">
                    <p><strong>Routing Number:</strong> {transaction.routingNumber}</p>
                    <p><strong>Swift Code:</strong> {transaction.swiftCode}</p>
                    <p><strong>Bank Address:</strong> {transaction.bankAddress}</p>
                  </div>
                  <div className="col-12">
                    <p><strong>Amount:</strong> ${transaction.amount}</p>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TransModal;
